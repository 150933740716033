import { useModalStore } from '@/stores/components/modalStore';

export default () => {
  const toggleActionModal = (
    toShow,
    data = {
      title: '',
      text: '',
      primaryBtnText: null,
      primaryBtnAction: null,
      secondaryBtnText: null,
      secondaryBtnAction: null,
    },
    alignment = {
      'justify-content': 'center',
      'align-content': 'center',
    },
  ) => {
    const store = useModalStore();
    store.$state.actionModal.toShow = toShow;
    store.$state.actionModal.options = data;
    store.$state.actionModal.options.alignment = alignment;
  };

  const showWalletModal = (
    data = {
      title: '',
      text: '',
      primaryBtnText: null,
      primaryBtnAction: null,
      secondaryBtnText: null,
      secondaryBtnAction: null,
      alignment: {},
    },
  ) => {
    const store = useModalStore();
    store.$state.walletModal.toShow = true;
    store.$state.walletModal.options = data;
  };

  const hideWalletModal = () => {
    const store = useModalStore();
    store.toggleWalletModal(false);
  };

  const hideSuccessModal = () => {
    const store = useModalStore();
    store.$state.successModal.toShow = false;
    store.$state.successModal.progressLevel = '';
  };

  const showSuccessModal = ({ progressLevel }) => {
    const store = useModalStore();
    store.$state.successModal.toShow = true;
    store.$state.successModal.progressLevel = progressLevel;
  };

  const toggleDofpFailureModal = (
    toShow,
    opts = {
      body: '',
    },
    alignment = {
      'justify-content': 'center',
      'align-content': 'center',
    },
  ) => {
    const store = useModalStore();
    store.$state.dofpFailureModal.toShow = toShow;
    store.$state.dofpFailureModal.options.body = opts?.body;
    store.$state.dofpFailureModal.options.alignment = alignment;
  };

  const toggleDofpNotifyModal = (
    toShow,
    opts = {
      title: '',
      body: '',
      primaryBtnText: null,
      primaryBtnAction: null,
      secondaryBtnText: null,
      secondaryBtnAction: null,
      alignment: {
        'justify-content': 'center',
        'align-content': 'center',
      },
    },
  ) => {
    const store = useModalStore();
    store.dofpNotifyModal.toShow = toShow;
    store.dofpNotifyModal.options = {
      ...store.dofpNotifyModal.options,
      ...opts,
    };
  };

  const toggleFormComponentModal = (
    toShow,
    componentPath,
    btnActions = {
      primaryBtnAction: () => toggleFormComponentModal(false),
      secondaryBtnAction: null,
    },
    alignment = {
      overflow: 'scroll',
      'justify-content': 'center',
      'align-content': 'start',
    },
  ) => {
    const store = useModalStore();

    store.$state.formComponentModal.toShow = toShow;
    store.$state.formComponentModal.options.componentPath = componentPath;
    store.$state.formComponentModal.options.primaryBtnAction = btnActions.primaryBtnAction;
    store.$state.formComponentModal.options.secondaryBtnAction = btnActions.secondaryBtnAction;
    store.$state.formComponentModal.options.alignment = alignment;
  };

  const toggleOrderRecoveryModal = (option = null) => {
    const store = useModalStore();
    store.orderRecoveryModal.toShow = option !== null ? option : !store.orderRecoveryModal.toShow;
  };

  return {
    toggleActionModal,
    showWalletModal,
    hideWalletModal,
    hideSuccessModal,
    showSuccessModal,
    toggleFormComponentModal,
    toggleDofpFailureModal,
    toggleDofpNotifyModal,
    toggleOrderRecoveryModal,
  };
};
