/*
Error handling codes mapped to BE codes for
pushing either error code messages to the form field
or action a callback to perform some action.
*/
// eslint-disable-next-line import/no-cycle
import useErrorCodeCallbacks from '@/composables/errors/useErrorCodeCallbacks';

const { useOrderChaining } = useErrorCodeCallbacks();

const APP_ERROR_CODES = new Map([
  [
    2,
    {
      field: null,
      error: null,
      callback: () => {
        useOrderChaining({
          title: 'Oops! Something went wrong',
          text: 'There was a problem with the authentication. Please try submitting with a new code.',
          primaryBtnText: 'Get a new code',
        });
      },
    },
  ],
  [
    180,
    {
      field: 'pinCode',
      error: 'Incorrect pin code. Please try again.',
      callback: null,
    },
  ],
  [
    5033,
    {
      field: null, // Since using the same error code 5033 for all of the Dlocal document numbers for each PSP
      error: 'Please enter a valid value.',
      callback: null,
    },
  ],
  [
    40441,
    {
      field: null,
      error: 'Order not found.',
      callback: null,
    },
  ],
  [
    42201,
    {
      field: 'mobile',
      error: 'Please enter a valid mobile number.',
      callback: null,
    },
  ],
]);

export default APP_ERROR_CODES;
