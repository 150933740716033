// eslint-disable-next-line import/no-cycle
import APP_ERROR_CODES from '@/assets/data/errorCodes';
import router from '@/router/index';
import { useErrorStore } from '@/stores/components/errorStore';

export default () => {
  function storeErrorInStore(errorCode, errorMessage) {
    const error = useErrorStore();
    error.$state.error.code = errorCode;
    error.$state.error.message = errorMessage;
  }

  function handleServerErrors(error) {
    const {
      code, message,
    } = error.response.data;

    const errorCode = code;
    const errorMsg = message;

    storeErrorInStore(errorCode, errorMsg);

    router.push({
      name: 'error',
      params: {
        errorCode,
      },
    });
  }

  function handleMappedErrorResponses(code) {
    const { field, error, callback } = APP_ERROR_CODES.get(code) || {};
    if (callback) {
      callback();
    }

    return field || error ? { field, error } : null;
  }

  function handleErrorInSpaFlow(errorCode, message) {
    storeErrorInStore(errorCode, message);
    router.push({
      name: 'error',
      params: {
        errorCode,
      },
    });
  }

  return {
    handleServerErrors,
    handleMappedErrorResponses,
    handleErrorInSpaFlow,
  };
};
