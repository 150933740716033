/* eslint-disable import/prefer-default-export */
import { i18n } from '@/packages/i18n';
import { featureFlag } from '@/composables/featureflags/useFeatureFlags';

const { t } = i18n.global;

// to be worked on when we want to extend the split test functionality
export const useLegacyIdentity = async () => {
  const val = await featureFlag('id-dashboard');
  return !val;
};

// map centry names to app routes
export const IDENTITY_MAPPING_ROUTES = {
  BANXA: {
    PERSONAL_DETAILS: {
      name: t('IdentityQueueStore.name.upNextBillingDetails'),
      route: '/identity/identityConfirmation',
      icon: 'person-icon',
      description: t('IdentityDashboard.description.personalDetails'),
    },
    POA: {
      name: t('IdentityQueueStore.name.upNextProofOfAddress'),
      route: '/identity/proofOfAddress',
      icon: 'location-icon-opt',
      description: t('IdentityDashboard.description.poa'),
    },
    SOURCE_FUNDS: {
      name: t('IdentityQueueStore.name.upNextSof'),
      route: '/identity/sourceOfFunds',
      icon: 'currency-icon-opt',
      description: t('IdentityDashboard.description.sof'),
    },
    PURPOSE_OF_TX: {
      name: t('IdentityQueueStore.name.upNextPurposeOfTransaction'),
      route: '/identity/purposeOfTransaction',
      icon: 'currency-icon-opt',
      description: t('IdentityDashboard.description.pot'),
    },
    SOF_DOCUMENT: {
      name: t('IdentityQueueStore.name.upNextSofDocUpload'),
      route: '/identity/sofDocumentUpload',
      icon: 'currency-icon-opt',
      description: t('IdentityDashboard.description.sofDocument'),
    },
    DOCUMENT: {
      name: t('IdentityQueueStore.name.upNextDocUpload'),
      route: '/identity/idUpload/nationalDriversLicence',
      icon: 'id-icon-opt',
      description: t('IdentityDashboard.description.document'),
    },
    DOCUMENT_NUMBER: {
      name: t('IdentityQueueStore.name.upNextDocNumber'),
      route: '/identity/documentNumber',
      icon: 'id-icon-opt',
      description: t('IdentityDashboard.description.documentNumber'),

    },
    SELFIE: {
      name: t('IdentityQueueStore.name.upNextSelfieDocUpload'),
      route: '/identity/selfie',
      icon: 'selfie-icon',
      description: t('IdentityDashboard.description.selfie'),
    },
    OCCUPATION: {
      name: t('IdentityQueueStore.name.upNextOccupation'),
      route: '/identity/occupation',
      icon: 'person-icon',
      description: t('IdentityDashboard.description.occupation'),
    },
    PEP_SOURCE_FUNDS: {
      name: t('IdentityQueueStore.name.upNextPepSof'),
      route: '/identity/pepSourceOfFunds',
      icon: 'currency-icon-opt',
      description: t('IdentityDashboard.description.pepSof'),
    },
    PEP_SOF_DOCUMENT: {
      name: t('IdentityQueueStore.name.upNextPepSofDoc'),
      route: '/identity/pepSofDocumentUpload',
      icon: 'id-icon-opt',
      description: t('IdentityDashboard.description.pepSofDocument'),
    },
    US_TAX_ID: {
      name: t('IdentityQueueStore.name.upNextSSN'),
      route: '/identity/ssn',
      icon: 'id-icon-opt',
      description: t('IdentityDashboard.description.usTaxId'),
    },
    TIN: {
      name: t('IdentityQueueStore.name.upNextSSN'),
      route: '/identity/ssn',
      icon: 'id-icon-opt',
      description: t('IdentityDashboard.description.usTaxId'),
    },
    DLOCAL_CPF: {
      name: t('IdentityQueueStore.name.upNextDlocalCpf'),
      route: '/identity/dlocal/cpf',
      icon: 'id-icon-opt',
      description: t('IdentityDashboard.description.dlocalCpf'),
    },
    DLOCAL_SAID: {
      name: t('IdentityQueueStore.name.upNextDlocalSaid'),
      route: '/identity/dlocal/said',
      icon: 'id-icon-opt',
      description: t('IdentityDashboard.description.dlocalSaid'),
    },
    DLOCAL_CURP: {
      name: t('IdentityQueueStore.name.upNextDlocalCurp'),
      route: '/identity/dlocal/curp',
      icon: 'id-icon-opt',
      description: t('IdentityDashboard.description.dlocalCurp'),
    },
    DLOCAL_DNI: {
      name: t('IdentityQueueStore.name.upNextDlocalDni'),
      route: '/identity/dlocal/dni',
      icon: 'id-icon-opt',
      description: t('IdentityDashboard.description.dlocalDni'),
    },
    DLOCAL_CC: {
      name: t('IdentityQueueStore.name.upNextDlocalCc'),
      route: '/identity/dlocal/cc',
      icon: 'id-icon-opt',
      description: t('IdentityDashboard.description.dlocalCc'),
    },
    DLOCAL_CI: {
      name: t('IdentityQueueStore.name.upNextDlocalCi'),
      route: '/identity/dlocal/ci',
      icon: 'id-icon-opt',
      description: t('IdentityDashboard.description.dlocalCi'),
    },
    DLOCAL_TIC: {
      name: t('IdentityQueueStore.name.upNextDlocalTic'),
      route: '/identity/dlocal/tic',
      icon: 'id-icon-opt',
      description: t('IdentityDashboard.description.dlocalTic'),
    },
    WALLET_OWNERSHIP: {
      name: t('IdentityQueueStore.name.upNextWalletOwnership'),
      route: '/identity/walletOwnership',
      icon: 'cc-icon-opt',
      description: t('IdentityDashboard.description.walletOwnership'),
    },
    DOFP_QUESTIONNAIRE_BUY_1: {
      name: t('IdentityQueueStore.name.upNextDofpQuestionnaire1'),
      route: '/identity/dofp/questionnaire/buy/1',
      icon: 'person-icon',
      description: t('IdentityDashboard.description.dofpQuestionnaire'),
    },
    DOFP_QUESTIONNAIRE_BUY_2: {
      name: t('IdentityQueueStore.name.upNextDofpQuestionnaire2'),
      route: '/identity/dofp/questionnaire/buy/2',
      icon: 'person-icon',
      description: t('IdentityDashboard.description.dofpQuestionnaire'),
    },
    DOFP_QUESTIONNAIRE_BUY_3: {
      name: t('IdentityQueueStore.name.upNextDofpQuestionnaire3'),
      route: '/identity/dofp/questionnaire/buy/3',
      icon: 'person-icon',
      description: t('IdentityDashboard.description.dofpQuestionnaire'),
    },
    DOFP_QUESTIONNAIRE_SELL_1: {
      name: t('IdentityQueueStore.name.upNextDofpQuestionnaire1'),
      route: '/identity/dofp/questionnaire/sell/1',
      icon: 'person-icon',
      description: t('IdentityDashboard.description.dofpQuestionnaire'),
    },
    DOFP_QUESTIONNAIRE_SELL_2: {
      name: t('IdentityQueueStore.name.upNextDofpQuestionnaire2'),
      route: '/identity/dofp/questionnaire/sell/2',
      icon: 'person-icon',
      description: t('IdentityDashboard.description.dofpQuestionnaire'),
    },
    DOFP_QUESTIONNAIRE_SELL_3: {
      name: t('IdentityQueueStore.name.upNextDofpQuestionnaire3'),
      route: '/identity/dofp/questionnaire/sell/3',
      icon: 'person-icon',
      description: t('IdentityDashboard.description.dofpQuestionnaire'),
    },
    CATEGORY: {
      name: t('IdentityQueueStore.name.upNextInvestorCategorisation'),
      route: '/identity/dofp/investor-declaration',
      icon: 'person-icon',
      description: 'Complete an investor type questionnaire',
    },
    COOLING_OFF: {
      name: t('IdentityQueueStore.name.upNextCoolingOff'),
      route: '/identity/dofp/cooling-period',
      icon: 'lock-icon-opt',
      description: 'Cooling off period',
    },
    UPDATE_DETAILS: {
      name: t('IdentityQueueStore.name.updateDetails'),
      route: '/identity/confirm-details',
      icon: 'person-icon',
      description: t('IdentityDashboard.description.updateDetails'),
    },
    ZEROHASH_TNC: {
      name: t('IdentityQueueStore.name.upNextZerohashTnc'),
      route: '/identity/tnc',
      icon: 'person-icon',
      description: t('IdentityDashboard.description.zerohashTnc'),
    },
    SCAM_CHECK_QUESTIONNAIRE: {
      name: t('IdentityQueueStore.name.upNextScamCheck'),
      route: '/identity/scam-check',
    },
  },
  SUMSUB: {
    PERSONAL_DETAILS: {
      name: t('IdentityQueueStore.name.upNextBillingDetails'),
      route: '/identity/identityConfirmation',
      icon: 'person-icon',
      description: t('IdentityDashboard.description.personalDetails'),
    },
    POA: {
      name: t('IdentityQueueStore.name.upNextProofOfAddress'),
      route: '/identity/sumsub',
      icon: 'location-icon-opt',
      description: t('IdentityDashboard.description.poa'),
    },
    SOURCE_FUNDS: {
      name: t('IdentityQueueStore.name.upNextSof'),
      route: '/identity/sumsub',
      icon: 'currency-icon-opt',
      description: t('IdentityDashboard.description.sof'),
    },
    PURPOSE_OF_TX: {
      name: t('IdentityQueueStore.name.upNextPurposeOfTransaction'),
      route: '/identity/sumsub',
      icon: 'currency-icon-opt',
      description: t('IdentityDashboard.description.pot'),
    },
    SOF_DOCUMENT: {
      name: t('IdentityQueueStore.name.upNextSofDocUpload'),
      route: '/identity/sumsub',
      icon: 'currency-icon-opt',
      description: t('IdentityDashboard.description.sofDocument'),
    },
    DOCUMENT: {
      name: t('IdentityQueueStore.name.upNextDocUpload'),
      route: '/identity/sumsub',
      icon: 'id-icon-opt',
      description: t('IdentityDashboard.description.document'),
    },
    SELFIE: {
      name: t('IdentityQueueStore.name.upNextSelfieDocUpload'),
      route: '/identity/sumsub',
      icon: 'selfie-icon',
      description: t('IdentityDashboard.description.selfie'),
    },
  },
  PLAID: {
    PERSONAL_DETAILS: {
      name: t('IdentityQueueStore.name.upNextBillingDetails'),
      route: '/identity/plaid',
      icon: 'person-icon',
      description: t('IdentityDashboard.description.personalDetails'),
    },
    POA: {
      name: t('IdentityQueueStore.name.upNextProofOfAddress'),
      route: '/identity/plaid',
      icon: 'location-icon-opt',
      description: t('IdentityDashboard.description.poa'),
    },
    SOURCE_FUNDS: {
      name: t('IdentityQueueStore.name.upNextSof'),
      route: '/identity/plaid',
      icon: 'currency-icon-opt',
      description: t('IdentityDashboard.description.sof'),
    },
    PURPOSE_OF_TX: {
      name: t('IdentityQueueStore.name.upNextPurposeOfTransaction'),
      route: '/identity/plaid',
      icon: 'currency-icon-opt',
      description: t('IdentityDashboard.description.pot'),
    },
    SOF_DOCUMENT: {
      name: t('IdentityQueueStore.name.upNextSofDocUpload'),
      route: '/identity/plaid',
      icon: 'currency-icon-opt',
      description: t('IdentityDashboard.description.sofDocument'),
    },
    DOCUMENT: {
      name: t('IdentityQueueStore.name.upNextDocUpload'),
      route: '/identity/plaid',
      icon: 'id-icon-opt',
      description: t('IdentityDashboard.description.document'),
    },
    SELFIE: {
      name: t('IdentityQueueStore.name.upNextSelfieDocUpload'),
      route: '/identity/plaid',
      icon: 'selfie-icon',
      description: t('IdentityDashboard.description.selfie'),
    },
    ZEROHASH_TNC: {
      name: t('IdentityQueueStore.name.upNextZerohashTnc'),
      route: '/identity/tnc',
      icon: 'person-icon',
      description: t('IdentityDashboard.description.zerohashTnc'),
    },
    TIN: {
      name: t('IdentityQueueStore.name.upNextSSN'),
      route: '/identity/plaid',
      icon: 'id-icon-opt',
      description: t('IdentityDashboard.description.usTaxId'),
    },
  },
};

export const COMPLETE_STATUES = ['completed', 'submitted'];
export const INCOMPLETE_STATUES = ['pending', 'failed', 'action-required'];
export const INCOMPLETE_PAYMENT_STATUES = ['pending', 'action required'];
// pages within identity queue that are journey blockers used for ID Dashboard routing logic
export const IDENTITY_BLOCKERS = ['COOLING_OFF'];

export default {
 IDENTITY_MAPPING_ROUTES, INCOMPLETE_STATUES, COMPLETE_STATUES, INCOMPLETE_PAYMENT_STATUES,
};
