import { ref } from 'vue';
import { isValueString } from '@/composables/helpers/useStringHelpers';
import { isObject } from '@vueuse/core';

import { IDENTITY_MAPPING_ROUTES } from './constants';

/**
 * Depending on the BE's call from queue we will require to clear the existing
 * queue on the FE and replace it with the new queue.
 */
const toResetIdentityQueue = ref(false);

/**
 * Leaves first occurence of Sumsub document only and all Banxa provider documents
 * @param {Array} routes
 * @returns {Array}
 */
const filterOneIDVItem = (routes) => {
  const providers = ['banxa', 'sumsub', 'plaid'];

  const firstSumsubOccurrence = routes.findIndex(
    (route) => route.provider.toLowerCase() === providers[1],
  );

  const firstPlaidOccurrence = routes.findIndex(
    (route) => route.provider.toLowerCase() === providers[2],
  );

  const filteredRoutes = routes.filter(
    (route, index) => route.provider.toLowerCase() === providers[0]
      || (route.provider.toLowerCase() === providers[1] && index === firstSumsubOccurrence)
      || (route.provider.toLowerCase() === providers[2] && index === firstPlaidOccurrence),
  );
  return filteredRoutes;
};

const organizeRoutes = (self, key, value, overrideRoutes, index) => {
  const banxaIdentityPages = Object.keys(IDENTITY_MAPPING_ROUTES.BANXA);
  const sumsubIdentityPages = Object.keys(IDENTITY_MAPPING_ROUTES.SUMSUB);
  const plaidIdentityPages = Object.keys(IDENTITY_MAPPING_ROUTES.PLAID);

  // see HE-2329 for data structure changes
  const provider = isObject(value)
    ? value?.provider.toUpperCase()
    : value.toUpperCase();
  const weight = isObject(value) ? value?.weight : index;

  if (!isValueString(provider)) {
    console.error('KYC Provider value is not returned');
    return;
  }

  if (!Object.getOwnPropertyDescriptor(IDENTITY_MAPPING_ROUTES, provider)) {
    console.error(`Wrong provider: ${provider} for identity`);
    return;
  }

  if (
    (provider === 'BANXA' && !banxaIdentityPages.includes(key))
    || (provider === 'SUMSUB' && !sumsubIdentityPages.includes(key))
    || (provider === 'PLAID' && !plaidIdentityPages.includes(key))
  ) {
    console.error(`Identity: ${key} has no mapped route for send to`);
    return;
  }

  const { route: url, name } = IDENTITY_MAPPING_ROUTES[provider][key];

  // if identityRoutes has no previous key of route name
  // or sumsub recollection from /status has occured, to allow sumsub
  // based named routes to repopulate route array
  const noNewKeys = !self.identityRoutes.find(
    (route) => route?.name.toLowerCase() === name.toLowerCase()
      && route?.provider.toUpperCase() === provider.toUpperCase(),
  );

  if (noNewKeys) {
    self.identityRoutes.push({
      url,
      provider,
      name,
      weight,
    });

    toResetIdentityQueue.value = true;

    self.calculateTotalSteps();
  }

  overrideRoutes.push({
    url,
    provider,
    name,
    weight,
  });
};

/**
 * Each document has its own weight, the lower the weight the higher in priority it's in the
 * array of documents
 *
 * @param {Array} routes
 * @returns {Array}
 */
const sortRoutesByWeight = (documents) => {
  const sortedDocs = documents.sort((docA, docB) => {
    if (docA.weight > docB.weight) return 1;
    if (docA.weight < docB.weight) return -1;
    if (docA?.weight === docB?.weight) return 0;
  });

  return sortedDocs;
};

/**
 * Sorting logic to arrange routes required from Centry based on weights
 * @param {Array} routes
 * @returns {Array}
 */
const sortRoutes = (routes) => {
  const filteredRoutes = filterOneIDVItem(routes);

  // push Questionnaire 2 before
  const questionnaireTwoIndex = filteredRoutes.findIndex(
    (route) => route.name === 'Dofp Questionnaire 2',
  );

  // // todo likely overkill and to delete this
  // const questionnaireTwoExists = filteredRoutes.find(
  //   (route) => route.name === 'Dofp Questionnaire 2',
  // );

  if (questionnaireTwoIndex === -1) {
    return sortRoutesByWeight(filteredRoutes);
  }

  // ignores weights
  const categoryIndex = filteredRoutes.findIndex(
    (doc) => doc.name === 'Investor Categorisation',
  );
  filteredRoutes.splice(categoryIndex, 0, routes[questionnaireTwoIndex]);
  filteredRoutes.splice(questionnaireTwoIndex + 1, 1);
  return filteredRoutes;
};

export default {
  organizeRoutes,
  sortRoutes,
  filterOneIDVItem,
  sortRoutesByWeight,
  toResetIdentityQueue,
};
