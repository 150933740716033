// eslint-disable-next-line import/no-cycle
import useApiHelpers from './helpers';

const { getRequest } = useApiHelpers();

export default function useCountryEndpoints() {
  const getCountries = async () => {
    const response = await getRequest('/countries');
    // TODO: [HE-2112] Remove trust wallet UK Hack post-go-live
    const responseHasUk = response.data.data.countries.some((country) => country[1] === 'gb');
    const ukArray = ['United Kingdom', 'gb', '44', 0];
    const isTrustWallet = ['trust.banxa.com', 'trustwallet.banxa.com', 'trustpromo.banxa.com', 'trust.banxa-sandbox.com'].includes(window.location.hostname);

    if (isTrustWallet && !responseHasUk) {
      response.data.data.countries.push(ukArray);
    }

    return response;
  };

  return {
    getCountries,
  };
}
