import useApiHelpers from '@/composables/api/helpers';
import {
  isValueString,
  lowerCaseItem,
} from '@/composables/helpers/useStringHelpers';
import { useOrderFormStore } from '@/stores/forms/orderFormStore';

const { getRequest, postRequest } = useApiHelpers();

export default function usePaymentEndpoints() {
  const getPayments = async () => {
    const response = await getRequest('/payment-methods');
    return response;
  };

  const getWorldPayToken = async () => {
    const response = await getRequest('/prepaid');
    return response;
  };

  const getCheckoutCCSettings = async () => {
    const response = await getRequest('/settings/prepaid/checkout');
    return response;
  };

  const getCircleAchSettings = async () => {
    const response = await getRequest('/settings/prepaid/circleach');
    return response;
  };

  const getFortressAchSettings = async () => {
    const response = await getRequest('/settings/prepaid/fortress');
    return response;
  };

  const getZhBnAchSettings = async () => {
    const response = await getRequest('/settings/prepaid/zhbnach');
    return response;
  };

  const getPlaidOpenBankSettings = async () => {
    const response = await getRequest('/settings/prepaid/plopenbank');
    return response;
  };

  const getPrimerCardSettings = async () => {
    const response = await getRequest('/settings/prepaid/primercc');
    return response;
  };

  const getPaymentAuthorizeStatus = () => getRequest('/payment/authorize');

  /**
   * Replace the browser history state when the payment is redirected
   */
  const replacePaymentHistoryState = () => {
    window.history.replaceState({
      ...window.history.state,
      isRedirected: true,
    }, '');
  };

  /**
   * Payment callback success redirect
   * @param {string} orderId
   * @param {any} next
   */
  const paymentCallbackSuccessRedirect = (orderId, next) => {
    replacePaymentHistoryState();
    next(`/status/${orderId}`);
  };

  const paymentCallback = async (to, next) => {
    const orderStore = useOrderFormStore();

    const { payment } = to.params;
    const { status } = to.params;
    const { orderId } = to.query;

    const paymentTypeCode = orderStore.getPaymentObject?.pspTypeCode;

    // TODO throw an error message if the payment from redirect url does not match with the payment saved in session
    // if(payment != buyStore.getPaymentObject?.value) throw error
    switch (paymentTypeCode?.toUpperCase()) {
      case 'IDEAL':
        await getRequest(
          `/payment/${payment}/callback/${status}?${new URLSearchParams({
            orderId: to.query.orderId,
          }).toString()}`,
          (response) => {
            paymentCallbackSuccessRedirect(response.data.orderId, next);
          },
        );
        break;
      case 'DLOCALZAIO':
      case 'DLOCALPIX':
      case 'DLOCALSPEI':
      case 'DLOCALARIO':
      case 'DLOCALCOPSE':
      case 'DLOCALCLKH':
      case 'DLOCALTHQR':
        await getRequest(
          `/payment/${payment}/callback/${status}?${new URLSearchParams({
            orderId: to.query.orderId,
            orderKey: to.query.orderKey,
          }).toString()}`,
          () => {
            paymentCallbackSuccessRedirect(to.query.orderKey, next);
          },
        );
        break;
      case 'POLI':
        await getRequest(
          `/payment/${payment}/callback/${status}?${new URLSearchParams({
            token: to.query.token,
          }).toString()}`,
          (response) => {
            paymentCallbackSuccessRedirect(response.data.orderId, next);
          },
        );
        break;
      case 'WORLDPAYGOOGLE':
        await getRequest(
          `/payment/${payment}/callback/${status}?${new URLSearchParams({
            orderId: to.query.orderId,
          }).toString()}`,
          () => {
            paymentCallbackSuccessRedirect(orderId, next);
          },
        );

        break;
      case 'CHECKOUTCREDIT':
        const ckoSessionId = to.query['cko-session-id'];
        checkoutCallbackTo1Cart(payment, status, orderId, ckoSessionId);
        if (isValueString(status) && lowerCaseItem(status) === 'success') {
          // Redirect to Order Status Page
          paymentCallbackSuccessRedirect(orderId, next);
        }
        // checkoutCallbackTo1Cart will redirect the user to the error page if status is not 'success'
        break;
      default:
    }
  };

  const checkoutCallbackTo1Cart = async (
    payment,
    status,
    orderId,
    ckoSessionId,
  ) => {
    await getRequest(
      `/payment/${payment}/callback/${status}?orderId=${orderId}&cko-session-id=${ckoSessionId}`,
    );
  };

  const getApplePayPageProps = async () => {
    const res = await getRequest('/prepaid/applepay');
    return res.data.data.options;
  };

  const getGooglePayPageProps = async () => {
    const res = await getRequest('/prepaid/gpay');
    return res.data.data.options;
  };

  const logApplePayErrorsApplePayErrors = (error) => {
    console.log(error);
    postRequest('/logging/log', {
      data: error,
    });
  };

  const validateApplePaySessionEndpoint = (
    validateSessionURL,
    subdomain,
    appleUrl,
    appleSuccessCallback,
    appleFailureCallback,
  ) => {
    postRequest(
      validateSessionURL,
      { validateURL: appleUrl, subdomain },
      // success callback
      (response) => {
        if (response.data.error) {
          console.error('failure validating session, ', response.data.error);
          appleFailureCallback(response.data.message);
        } else {
          appleSuccessCallback(response.data);
        }
      },
      // failure callback
      (error) => {
        console.error('failure validating session, ', error);
        const { response } = error;
        const errorMsg = response.data.message;

        appleFailureCallback(errorMsg);
      },
      { headers: { 'Access-Control-Allow-Origin': '*' } },
    );
  };

  const postCircleAchPaymentCallback = (
    callbackUrl,
    payload,
    successCallback,
    pendingCallback,
  ) => {
    postRequest(callbackUrl, payload, (res) => {
      const response = res.data?.status;
      const orderId = res.data?.orderId;
      if (response === 'success' && orderId) {
        successCallback(orderId);
        return;
      }
      pendingCallback();
    });
  };

  const postPlaidDataCallback = (
    callbackUrl,
    payload,
    successCallback,
    failureCallback,
  ) => {
    postRequest(
      callbackUrl,
      payload,
      (res) => {
        const response = res.data?.status;
        const orderId = res.data?.orderId;
        if (response === 'success' && orderId) {
          successCallback(orderId);
        }
      },
      (failureRes) => {
        failureCallback(failureRes?.data);
      },
    );
  };

  const checkPrimerPaymentStatus = (payment) => {
    const { id, orderId } = payment || {};
    const payload = {
      payment: {
        id,
        orderId,
      },
    };
    // Should not interrupt the user journey with the error message
    return postRequest('/primercc/attempt/callback', payload, false, false, {}, false);
  };

  return {
    getPayments,
    getWorldPayToken,
    getCheckoutCCSettings,
    getCircleAchSettings,
    getFortressAchSettings,
    paymentCallback,
    getApplePayPageProps,
    getGooglePayPageProps,
    validateApplePaySessionEndpoint,
    postCircleAchPaymentCallback,
    postPlaidDataCallback,
    getPlaidOpenBankSettings,
    getPrimerCardSettings,
    getPaymentAuthorizeStatus,
    checkPrimerPaymentStatus,
    getZhBnAchSettings,
  };
}
