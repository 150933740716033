export default {
    en: {
      shortFormat: {
        dateStyle: 'short',
      },
      longFormat: {
        year: 'numeric',
month: 'short',
day: 'numeric',
        weekday: 'short',
hour: 'numeric',
minute: 'numeric',
      },
    },
};
