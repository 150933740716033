import { computed } from 'vue';

import { useBuyOrderFormStore } from '@/stores/forms/buyOrderFormStore';
import { useGlobalStore } from '@/stores/globalStore';

export default function useStoreFilters() {
  const keysToSkipEmptyCheck = ['lockFiat']; // these can be true/false

  // special cases for keys as their required fields are dynamic
  const checkDynamicKeysRequired = (key) => {
    const buyStore = useBuyOrderFormStore();
    const globalStore = useGlobalStore();
    const showWalletTagByBlockchain = computed(
      () => buyStore.getWalletMemoByBlockchain,
    );
    let flag;

    switch (key) {
      // wallet tags can be required or not required by the blockchain's settings
      // if not required check for walletTag in payload to skip required check
      case 'walletAddressTag':
        flag = showWalletTagByBlockchain.value?.required
          || (!showWalletTagByBlockchain.value?.required
            && buyStore.form.walletAddressTag.value);
        break;
      case 'mobileNumber':
        if (
          globalStore.getMerchantSettings.VALIDATE_MSISDN
          || buyStore.getPaymentObject.require_mobile
        ) {
          flag = true;
        } else {
          flag = false;
        }
        break;
      default:
        flag = false;
    }

    return flag;
  };
  const getFormPayload = (store) => Object.entries(store.form).reduce((accumulator, [key, val]) => {
      const { required, value, ...rest } = val;
      if (required || checkDynamicKeysRequired(key)) {
        accumulator[key] = value?.value ? value.value : value;
      }

      return accumulator;
    }, {});

  const formHasErrors = (store) => {
    const anyErrors = Object.values(store.form)
      .filter((field) => Object.getOwnPropertyDescriptor(field, 'error'))
      .filter((field) => field.error !== '');

    const anyFieldsEmpty = Object.entries(store.form)
      .filter(
        ([key, field]) => Object.getOwnPropertyDescriptor(field, 'required')
          && field.required
          && !keysToSkipEmptyCheck.includes(key),
      )
      .some(
        ([key, field]) => field.value === null || field.value === '' || field.value === false,
      );

    return anyErrors.length > 0 || anyFieldsEmpty;
  };

  const getErrorByField = (store) => (field) => {
    if (
      !store
      || !store.form[field]
      || !Object.getOwnPropertyDescriptor(store.form[field], 'error')
    ) {
      return '';
    }

    return store.form[field].error;
  };

  const getErrorInForm = (store) => {
    const anyErrors = Object.entries(store.form)
      .filter(([key, value]) => Object.getOwnPropertyDescriptor(value, 'error'))
      .filter(([key, value]) => value.error !== '');
    return Object.keys(Object.fromEntries(anyErrors));
  };

  const isOrderFromTheApi = (form) => form && Object.keys(form).some((key) => form[key]?.fromApi);

  return {
    getFormPayload,
    formHasErrors,
    getErrorByField,
    getErrorInForm,
    isOrderFromTheApi,
  };
}
