/*
Callback functions used by App error codes
*/
import { useGlobalStore, ORDER_TYPE } from '@/stores/globalStore';
import { useBuyOrderFormStore } from '@/stores/forms/buyOrderFormStore';
import { useVerificationStore } from '@/stores/forms/verificationFormStore';
import { useVerificationCodeStore } from '@/stores/forms/verificationCodeFormStore';
import { useSellOrderFormStore } from '@/stores/forms/sellOrderFormStore';

import useApiHelpers from '@/composables/api/helpers';
import useModalActions from '@/composables/store/modal/useModalActions';

const { toggleActionModal } = useModalActions();
const { postRequest, multiPostRequests } = useApiHelpers();

export default () => {
  /*
   */
  const useOrderChaining = (
    modalData = { title: '', text: '', primaryBtnText: '' },
  ) => {
    const modalOptions = {
      title: modalData.title,
      text: modalData.text,
      primaryBtnText: modalData.primaryBtnText,
      primaryBtnAction: () => {
        const promises = [];
        const buyStore = useBuyOrderFormStore();
        const sellStore = useSellOrderFormStore();
        const verificationStore = useVerificationStore();
        const verificationCodeStore = useVerificationCodeStore();
        const globalStore = useGlobalStore();
        const orderChainingApis = [
          {
            data: buyStore.getFormPayload,
            apiUrl: '/orders/buy',
          },
          {
            data: sellStore.getFormPayload,
            apiUrl: '/orders/sell',
          },
          {
            data: verificationStore.getFormPayload,
            apiUrl: '/accounts',
          },
        ];

        const removeOrderApiCallByActiveForm = () => (globalStore.global.mode === ORDER_TYPE.BUY
            ? orderChainingApis.splice(1, 1)
            : orderChainingApis.splice(0, 1));

        removeOrderApiCallByActiveForm();
        orderChainingApis.forEach(({ data, apiUrl }) => {
          promises.push(postRequest(apiUrl, data, () => null));
        });

        multiPostRequests(promises, (response) => {
          // reset pincode form
          globalStore.setIsFormLoading(false);
          verificationCodeStore.updateValue('pinCode', '');
        });
      },
    };
    return toggleActionModal(true, modalOptions);
  };

  return {
    useOrderChaining,
  };
};
