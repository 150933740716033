/* eslint-disable no-param-reassign */
import baseFormValidators from '@/composables/validations/baseFormValidators';
import { i18n } from '@/packages/i18n';
import useFileInputValidations from '@/composables/validations/useFileInputValidations';
import { useOccupationStore } from '@/stores/forms/occupationFormStore';
import walletAddressValidator from '@/composables/validations/walletAddressValidator';

const { t } = i18n.global;
const {
  isEmpty,
  minLength,
  isEmail,
  getMobileErrorMsg,
  getMobileCustomErrorMsg,
} = baseFormValidators();
const {
  isFileTypeAllowed,
  isSingleFileSizeInLimits,
  areMultiFileSizesInLimits,
  areMultiFileTypesAllowed,
} = useFileInputValidations();
const { validateWalletByBlockchains, validateWalletBySelectedBlockchain } = walletAddressValidator();

export default function useFormValidation() {
  const validateNameField = (fieldName, fieldValue) => {
    if (!fieldValue) {
      return;
    }

    return minLength(fieldValue, 1);
  };

  const validateSurnameField = (fieldName, fieldValue) => {
    if (!fieldValue) {
      return;
    }

    // eslint-disable-next-line consistent-return
    return minLength(fieldValue, 1);
  };

  const validateEmailField = (fieldName, fieldValue) => {
    if (!fieldValue) {
      return;
    }

    return isEmpty(fieldName, fieldValue) || isEmail(fieldName, fieldValue);
  };

  const validateMobileField = (mobile, countryCode) => {
    if (!mobile) {
      return;
    }

    return getMobileErrorMsg(mobile, countryCode) === ''
      ? getMobileCustomErrorMsg(mobile, countryCode)
      : getMobileErrorMsg(mobile, countryCode);
  };

  const validateOccupationField = (fieldName, fieldValue) => {
    const occupationStore = useOccupationStore();
    return occupationStore.getErrorByField('occupationOther');
  };

  const validateCheckboxField = (fieldName, fieldValue) => {
    if (!fieldValue || fieldValue == false) {
      return '';
    }
    return isEmpty(fieldName, fieldValue);
  };

  const validateCountryField = (fieldName, country, countryList) => (!countryList.includes(country)
      ? `The input is not a valid ${fieldName}`
      : '');

  const validateVerificationCodeField = (field, pincodeValue) => {
    if (!pincodeValue) {
      return;
    }
    const pincodeLength = import.meta.env.VITE_APP_PINCODE_LENGTH;

    return pincodeValue.length != pincodeLength
      ? t('VerificationCodeInputField.error.verificationCodeErrorMsg')
      : '';
  };

  const validateWalletAddressField = async (walletAddress, blockchain) => {
    if (!walletAddress || !blockchain || blockchain.length === 0) {
      return '';
    }

    if (blockchain.includes('No valid blockchains available')) {
      return t('FormInput.error.validateWalletAddressErrorMsg');
    }

    const validatedWalletAddress = await validateWalletBySelectedBlockchain(
      walletAddress,
      blockchain,
    );

    return Object.values(validatedWalletAddress).includes(false)
      ? t('FormInput.error.validateWalletAddressErrorMsg')
      : '';
  };
  const validateFiatField = (fiatAmount, fiatType, transactionalLimit) => {
    if (!fiatAmount || !transactionalLimit) {
      return '';
    }
    const { min, max } = transactionalLimit;

    return parseInt(fiatAmount, 10) > parseInt(max, 10)
      || parseInt(fiatAmount, 10) < parseInt(min, 10)
      ? t('FormInput.error.validateFiatAmountErrorMsg', {
          fiatType,
          min,
          max,
        })
      : '';
  };

  const validateCoinField = (coinAmount, coinType, blockchain, coinData) => {
    if (!coinAmount || !coinData || !coinType) {
      return '';
    }

    const activeCoinData = coinData.find(
      (coin) => coinType === coin?.coin_code,
    );

    const minValueByCoin = activeCoinData?.min_value;
    const blockchainDataByCoin = activeCoinData.blockchains.find(
      (coin) => blockchain.value === coin.code,
    );
    const minValueByBlockchainCoin = blockchainDataByCoin?.min_value;
    const minCoin = minValueByBlockchainCoin || minValueByCoin;

    return coinAmount < minCoin
    ? t('FormInput.error.validateCoinAmountErrorMsg', { minCoin })
    : '';
  };

  const validateBlockchainField = async (walletAddress, blockchains) => {
    if (!walletAddress || !blockchains || blockchains.length === 0) {
      return '';
    }

    const validatedWalletAddress = await validateWalletByBlockchains(
      walletAddress,
      blockchains,
    );
    const walletsAreFalse = (wallet) => wallet === false;

    return Object.values(validatedWalletAddress).every(walletsAreFalse)
      ? t('FormInput.error.blockchainValidationErrorMsg')
      : '';
  };

  const validateFileInput = (fieldName, file) => isFileTypeAllowed(file) || isSingleFileSizeInLimits(file);

  const validateMultiFileInput = (fieldName, files) => areMultiFileSizesInLimits(files) || areMultiFileTypesAllowed(files);

  const validateDobField = (fieldName, dob) => {
    if (!dob) {
      return;
    }
    // const store = useIdentityConfirmationStore();
    // const isUSA = store.getCountry === 'United States';
    // const dobFormat = isUSA ? 'MM-DD-YYYY' : 'DD-MM-YYYY';
    const dobFormat = 'DD-MM-YYYY';
    // let day; let month; let year;
    const [day, month, year] = dob.split('-');

    // https://stackoverflow.com/a/7091965
    function getAge(dateString) {
      const today = new Date();
      const birthDate = new Date(dateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age -= 1;
      }
      return age;
    }

    // if (dobFormat === 'MM-DD-YYYY') {
    //   [month, day, year] = split;
    // } else {
      // [day, month, year] = split;
    // }
    // [day, month, year] = split;

    const formatToDateString = `${year}-${month}-${day}`;
    const age = getAge(formatToDateString);
    const allowedUpper = import.meta.env.VITE_APP_HIGHEST_ALLOWED_DOB;
    const allowedLower = import.meta.env.VITE_APP_LOWEST_ALLOWED_DOB;

    if (!day || !month || !year) {
      return;
    }

    return !age
      || age > allowedUpper
      || age < allowedLower
      || year.length !== 4 // chrome allows 2 char years
      ? `${t('FormInput.error.dobValidationErrorMsg')} ${dobFormat}`
      : '';
  };

  const validateSSNField = (field, ssn) => {
    if (!ssn) return '';
    return /^(?!(000|666|9))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/.test(ssn)
      ? ''
      : t('FormInput.error.ssnValidationErrorMsg');
  };

  const validateBSBField = (field, bsb) => {
    if (!bsb) return '';
    return bsb.length === 7 && /^[0-9]{3}-[0-9]{3}$/.test(bsb)
      ? ''
      : t('FormInputField.error.bsbErrorMsg');
  };

  const validateAccountNumberField = (field, accountNo) => {
    if (!accountNo) return '';
    return accountNo.length <= 9
      && accountNo.length >= 5
      && /^[0-9]+$/.test(accountNo)
      ? ''
      : t('FormInputField.error.accountNumberFieldErrorMsg');
  };

  const validateIBANField = (field, iban) => {
    if (!iban) return '';
    return iban.length <= 34 && iban.length >= 5 && /^[A-Za-z0-9 ]*$/.test(iban)
      ? ''
      : t('FormInput.error.ibanValidationErrorMsg');
  };

  const validateWalletAddressTag = (walletTag, walletTagData) => {
    if (!walletTag || !walletTagData) {
      return '';
    }

    const { name, regex } = walletTagData;
    const coinRegex = new RegExp(regex);
    return `${walletTag}`.search(coinRegex) === -1
      ? t('FormInput.error.walletAddressTagValidationErrorMsg', {
          name: name.toLowerCase(),
        })
      : '';
  };

  const validateCurpField = (field, curp) => {
    if (!curp) {
      return '';
    }
    return /^[a-zA-Z]{4}\d{6}[a-zA-Z]{6}[a-zA-Z0-9]{2}$/.test(curp)
      ? ''
      : t('FormInput.error.curpValidationErrorMsg');
  };

  const validateCpfField = (field, cpf) => {
    if (!cpf) {
      return '';
    }
    return /^\d{11}$/.test(cpf)
      ? ''
      : t('FormInput.error.cpfValidationErrorMsg');
  };

  const validateSaidField = (field, said) => {
    if (!said) {
      return '';
    }
    return /^\d{13}$/.test(said)
      ? ''
      : t('FormInput.error.saidValidationErrorMsg');
  };

  const validateSortCodeField = (field, sortCode) => {
    if (!sortCode) return '';
    return sortCode.length === 8
      && /^[0-9]{2}-[0-9]{2}-[0-9]{2}$/.test(sortCode)
      ? ''
      : t('ActionModal.title.sortFieldErrorMsg');
  };

  const validateCcField = (field, cc) => {
    if (!cc) {
      return '';
    }
    return /^\d{6,10}$/.test(cc)
      ? ''
      : t('FormInput.error.ccValidationErrorMsg');
  };

  const validateDniField = (field, dni) => {
    if (!dni) {
      return '';
    }
    return /^\d{7,9}$|^\d{11}$/.test(dni)
      ? ''
      : t('FormInput.error.dniValidationErrorMsg');
  };

  const validateCiField = (field, ci) => {
    if (!ci) {
      return '';
    }
    return /^\d{7,8}([\dkK]){1}$/.test(ci)
      ? ''
      : t('FormInput.error.ciValidationErrorMsg');
  };

  const validateTicField = (field, tic) => {
    if (!tic) {
      return '';
    }
    return /^\d{13}$/.test(tic)
      ? ''
      : t('FormInput.error.ticValidationErrorMsg');
  };

  const validateAddressLine1Field = (field, address) => {
    if (!address) return '';
    return /^(?!.*(?:(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(box|bin)[-.\s]*)|.*((p |post)[-.\s]*(box|bin)[-.\s]*)))).*$/i.test(
      address,
    )
      ? ''
      : t('FormInput.error.addressLine1ValidationErrorMsg');
  };

  const validateAddress = (address, getUSState, isUnitedStates, countries, store) => {
    const subPremise = address?.subpremise?.long_name;
    const streetNumber = address?.street_number?.long_name;
    const streetName = address?.route?.long_name;
    const state = address?.administrative_area_level_1?.short_name;
    const USStateExists = isUnitedStates
    && state
    && getUSState(address?.administrative_area_level_1?.short_name);
    const postcode = address?.postal_code?.long_name;
    const country = address?.country?.long_name;
    const countryIsValid = country && countries.find(
      (supportedCountry) => supportedCountry.name.toLowerCase() === country.toLowerCase(),
    );

    if (country && !countryIsValid) {
      store.form.country.error = t('FormSelect.error.countryErrorMsg', { country });
    }

    // optional fields
    if (subPremise) {
      return !subPremise;
    }

    // required fields
    return (
    !streetNumber
    || !streetName
    || !state
    || !!(USStateExists)
    || !postcode
    || !countryIsValid);
  };

  const validateAutoComplete = (
    address,
    isUnitedStates,
    autocompleteAddressErrors,
    showManualAddress,
    getUSState,
    store,
    countries,
  ) => {
    const isNotAValidAddress = validateAddress(
      address,
      getUSState,
      isUnitedStates.value,
      countries,
      store,
    );

    if (isNotAValidAddress) {
      autocompleteAddressErrors.value = t('FormInput.error.addressValidationErrorMsg');
      showManualAddress.value = true;
    } else {
      autocompleteAddressErrors.value = '';
    }

    if (isUnitedStates.value
      && !(address?.administrative_area_level_1?.short_name
      && getUSState(address?.administrative_area_level_1?.short_name))) {
      store.form.state.error = t('FormInput.error.stateErrorMsg', { state: address?.administrative_area_level_1?.short_name });
    } else {
      store.form.state.error = '';
    }
  };

  return {
    validateNameField,
    validateSurnameField,
    validateEmailField,
    validateCountryField,
    validateCheckboxField,
    validateVerificationCodeField,
    validateWalletAddressField,
    validateBlockchainField,
    validateFileInput,
    validateMultiFileInput,
    validateMobileField,
    validateFiatField,
    validateCoinField,
    validateDobField,
    validateCurpField,
    validateCpfField,
    validateSaidField,
    validateSSNField,
    validateOccupationField,
    validateBSBField,
    validateAccountNumberField,
    validateIBANField,
    validateWalletAddressTag,
    validateSortCodeField,
    validateCcField,
    validateDniField,
    validateCiField,
    validateTicField,
    validateAddressLine1Field,
    validateAutoComplete,
  };
}
