import { useProgressStore } from '@/stores/progressStore';

const keys = ['verification', 'payment', 'identity'];

export default function useProgressBarActions() {
  const updateToComplete = (keysToUpdate) => {
    const store = useProgressStore();
    keysToUpdate.forEach((key) => {
      store.updateToComplete(key);
    });
  };

  const updateToPending = (key) => {
    const store = useProgressStore();
    store.updateToPending(key);
  };

  const updateToIncomplete = (key) => {
    const store = useProgressStore();
    store.updateToIncomplete(key);
  };

  const resetAllProgress = () => {
    const store = useProgressStore();
    for (let i = 0; i < keys.length; i++) {
      store.updateToIncomplete(keys[i]);
    }
  };

  return {
    updateToComplete,
    updateToPending,
    updateToIncomplete,
    resetAllProgress,
  };
}
