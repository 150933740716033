/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia';
import useLocalStorage from '@/composables/storage/useLocalStorage';

const { checkLocalStorage, bindLocalStorage } = useLocalStorage();

const progressObj = {
  verification: 'PENDING',
  identity: 'INCOMPLETE',
  payment: 'INCOMPLETE',
};

export const useProgressStore = defineStore({
  id: 'progress',
  state: () => ({
    progress: checkLocalStorage('progress', progressObj),
  }),
  actions: {
    updateToComplete(key) {
      this.progress[key] = 'COMPLETE';
      bindLocalStorage(this.$id, this.progress);
    },
    updateToPending(key) {
      this.progress[key] = 'PENDING';
      bindLocalStorage(this.$id, this.progress);
    },
    updateToIncomplete(key) {
      this.progress[key] = 'INCOMPLETE';
      bindLocalStorage(this.$id, this.progress);
    },
  },
});
