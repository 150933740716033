import { useGlobalStore } from '@/stores/globalStore';

export default () => {
  const useLoadingStateAction = (isLoading = false) => {
    const store = useGlobalStore();
    store.setIsFormLoading(isLoading);
  };

  return {
    useLoadingStateAction,
  };
};
