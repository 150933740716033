/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia';
import { ref } from 'vue';

import filters from '@/composables/store/filters';
import useGlobalActions from '@/composables/store/globalActions';
import useFormValidation from '@/composables/validations/useFormValidation';

const { formHasErrors, getErrorByField } = filters();
const { updateErrorFieldByKey } = useGlobalActions();
const { validateVerificationCodeField } = useFormValidation();

const formObject = ref({
  pinCode: {
    value: null,
    error: '',
    required: true,
    validator: validateVerificationCodeField,
  },
});

export const useVerificationCodeStore = defineStore({
  id: 'verificationCode',
  state: () => ({
    form: formObject,
  }),
  getters: {
    formHasErrors: (store) => formHasErrors(store),
    getErrorByField: (store) => (field) => getErrorByField(store)(field),
  },
  actions: {
    updateValue(field, val) {
      if (Object.getOwnPropertyDescriptor(this.form[field], 'validator')) {
        const { validator } = this.form[field];
        this.form[field].error = validator(field, val);
      }
      this.form[field].value = val;
    },

    updateErrorFieldByKey(field, error) {
      updateErrorFieldByKey(this.form, field, error);
    },
  },
});
