import useModalActions from '@/composables/store/modal/useModalActions';
// import handleDirectVisit from '@/router/middleware/handleDirectVisit';
import useApiHelpers from '@/composables/api/helpers';
import usePaymentEndpoints from '@/composables/api/usePaymentEndpoints';
import useProgressBarActions from '@/composables/progress/useProgressBarActions';

const { updateToComplete, updateToPending, resetAllProgress } = useProgressBarActions();
const { paymentCallback } = usePaymentEndpoints();
const { toggleOrderRecoveryModal } = useModalActions();
const { getRequest } = useApiHelpers();

const routes = [
  {
    path: '/',
    name: 'order',
    component: () => import('@/views/OrderView.vue'),
    beforeEnter() {
      resetAllProgress();
      // handleDirectVisit();
    },
  },
  {
    path: '/recovery/:orderId?',
    name: 'order-recovery',
    component: () => import('@/views/OrderRecoveryView.vue'),
    beforeEnter() {
      toggleOrderRecoveryModal();
      resetAllProgress();
    },
  },
  {
    path: '/recovery/opt-out/:orderId?',
    name: 'order-recovery.opt-out',
    component: () => import('@/views/OrderRecoveryOptOutView.vue'),
  },
  {
    path: '/papi/transit/',
    name: 'order.papi',
    component: () => import('@/views/OrderView.vue'),
    beforeEnter() {
      resetAllProgress();
    },
  },
  {
    path: '/verification',
    name: 'verification',
    component: () => import('@/views/VerificationView.vue'),
    beforeEnter() {
      updateToPending('verification');
    },
  },
  {
    path: '/verification/code',
    name: 'verificationCode',
    component: () => import('@/views/VerificationCodeView.vue'),
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('@/views/payment/PaymentView.vue'),
  },
  {
    path: '/payment/plaid',
    name: 'payment.plaid.relink',
    component: () => import('@/views/plaidRedirect/PlaidRedirectView.vue'),
    async beforeEnter(to, from, next) {
      updateToComplete(['verification', 'identity']);
      updateToPending('payment');
      next();
    },
    children: [
      {
        path: 'relink',
        component: () => import('@/views/plaidRedirect/OpenBankingView.vue'),
      },
      {
        path: 'relinkach',
        component: () => import('@/views/plaidRedirect/AchView.vue'),
      },
    ],
  },
  {
    path: '/payment/:payment/callback/:status',
    name: 'payment.callback',
    component: () => import('@/views/PaymentView.vue'),
    beforeEnter(to, from, next) {
      paymentCallback(to, next);
    },
  },
  {
    path: '/payment/:payment',
    name: 'payment.payment',
    component: () => import('@/views/PaymentView.vue'),
    async beforeEnter(to, from, next) {
      updateToComplete(['verification', 'identity']);
      updateToPending('payment');
      next();
    },
  },
  {
    path: '/coindeposit/*',
    name: 'coindeposit',
    component: () => import('@/views/CoinDepositView.vue'),
    beforeEnter() {
      updateToComplete(['verification', 'identity']);
      updateToPending('payment');
    },
  },
  {
    path: '/deposit',
    name: 'deposit',
    component: () => import('@/views/DepositView.vue'),
  },
  {
    path: '/coindeposit/:orderId',
    name: 'coindeposit',
    component: () => import('@/views/CoinDepositView.vue'),
    beforeEnter() {
      updateToComplete(['verification', 'identity']);
      updateToPending('payment');
    },
  },
  {
    path: '/status/:orderId',
    name: 'summary',
    component: () => import('@/views/OrderSummaryView.vue'),
    beforeEnter() {
      updateToComplete(['verification', 'identity', 'payment']);
    },
  },
  {
    path: '/widget',
    name: 'widget',
    component: () => import('@/views/WidgetView.vue'),
    beforeEnter() {
      resetAllProgress();
    },
  },
  {
    path: '/error/:errorCode?',
    name: 'error',
    component: () => import('@/views/ErrorView.vue'),
    props: (route) => ({ ...route.query }),
  },
  {
    path: '/:pathMatch(.*)*', // Updated catch-all route definition
    name: 'not-found',
    redirect: { name: 'error', query: { errorCode: '404' } }, // Redirect to the 'error' route
  },
  {
    path: '/cancel/:orderId',
    name: 'cancel.order',
    component: () => import('@/views/PaymentCoolingView.vue'),
    async beforeEnter(to, from, next) {
      await getRequest(`/cancel/${to.params.orderId}`, () => {
        next(`/status/${to.params.orderId}`);
      });
    },
  },
];

export default routes;
