// files are bytes: 10(mb) * 1024 * 1024 = 10485760
import { i18n } from '@/packages/i18n';

const ACCEPTED_FILE_TYPES = ['image/jpg', 'image/jpeg', 'image/png'];
const ACCEPTED_FILE_SIZE = 10485760;
const { t } = i18n.global;

export default function useFileInputValidations() {
  const isFileTypeAllowed = (file) => (!ACCEPTED_FILE_TYPES.includes(file.type)
      ? t('FileUploadField.error.fileTypeErrorMsg')
      : '');
  const isSingleFileSizeInLimits = (file) => (file.size > ACCEPTED_FILE_SIZE
      ? t('FileUploadField.error.fileSizeErrorMsg')
      : '');

  const areMultiFileSizesInLimits = (files) => {
    const totalFileSizes = Object.keys(files).reduce(
      (acc, file) => files[file].size + acc,
      0,
    );
    return totalFileSizes > ACCEPTED_FILE_SIZE
      ? t('MultiFileUploadField.error.fileSizeLimitErrorMsg')
      : '';
  };

  const areMultiFileTypesAllowed = (files) => {
    const fileTypeAllowed = (fileType) => ACCEPTED_FILE_TYPES.includes(fileType.type);
    const fileTypeNotAllowed = !Object.values(files).every(fileTypeAllowed);

    return fileTypeNotAllowed
      ? t('MultiFileUploadField.error.fileTypesAllowedErrorMsg')
      : '';
  };

  return {
    isFileTypeAllowed,
    isSingleFileSizeInLimits,
    areMultiFileSizesInLimits,
    areMultiFileTypesAllowed,
    ACCEPTED_FILE_TYPES,
  };
}
