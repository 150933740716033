// eslint-disable-next-line import/no-cycle
import { useGlobalStore } from '@/stores/globalStore';
import http from './http';
import useErrorHandling from './useErrorHandling';

export default () => {
  const { handleServerErrors } = useErrorHandling();

  const getToken = async () => http
      .get('/token')
      .then((response) => {
        sessionStorage.setItem('token', response.data.bearer_token);
        return response.data.bearer_token;
      })
      .catch((err) => {
        handleServerErrors(err);
      });

  const waitUntilTokenRetrieved = async () => {
    const res = await new Promise((resolve) => {
      const checkInterval = setInterval(() => {
        const authToken = sessionStorage.getItem('token');
        if (authToken) {
          resolve(authToken);
          clearInterval(checkInterval);
        }
      }, 250);
    });

    return res;
  };

  const fetchAuthToken = async () => {
    const globalStore = useGlobalStore();
    let authToken = sessionStorage.getItem('token');
    if (authToken) {
      globalStore.$state.token = authToken;
      return authToken;
    }

    if (globalStore.$state.fetchingToken) {
      authToken = await waitUntilTokenRetrieved();
    } else {
      globalStore.$state.fetchingToken = true;
      await http.get('/csrf-cookie').catch((err) => {
        handleServerErrors(err);
      });

      authToken = await getToken();

      globalStore.$state.fetchingToken = false;
    }

    globalStore.$state.token = authToken;
    return authToken;
  };

  return {
    fetchAuthToken,
    waitUntilTokenRetrieved,
  };
};
