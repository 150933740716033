export default () => {
  /**
   * Description
   * @param {Array} coinData
   * @param {String} activeCoin
   * @returns {Boolean | Array}
   * */
  function getActiveCoinData(coinData, activeCoin) {
    if (coinData.length <= 0 || !activeCoin) {
      return false;
    }

    return coinData.find((coin) => coin.coin_code === activeCoin);
  }

  /**
   * Gets the metamask id chain
   *
   * @param {pinia Array} coinData
   * @param {pinia String} activeCoin
   * @param {pinia String} activeBlockchain
   * @returns String
   */

  function getMetaMaskIdChainBySelectedBlockchain(
    coinData,
    activeCoin,
    activeBlockchain,
  ) {
    const activeCoinData = getActiveCoinData(coinData, activeCoin);

    if (!activeCoinData) {
      return '';
    }

    const selectedBlockchainData = activeCoinData.blockchains.find(
      (blockchain) => blockchain.code === activeBlockchain.value,
    );

    const metamaskId = selectedBlockchainData
      && Object.getOwnPropertyDescriptor(selectedBlockchainData, 'metamask_id')
      && selectedBlockchainData.metamask_id
        ? `0x${selectedBlockchainData.metamask_id.toString(16)}`
        : '';

    return metamaskId;
  }

  /**
   * Gets the default blockchain (is_default)
   * from active coin's blockchains data
   * @param {Array} coinData
   * @returns {undefined | String | Object: {name, value}}
   * */
  const getCurrentCoinDefaultBlockchain = (coinData) => {
    if (coinData.length <= 0) {
      return '';
    }

    const defaultBlockchainByCoin = coinData.blockchains.find(
      (coin) => coin.is_default === true,
    );

    if (!defaultBlockchainByCoin) {
      return '';
    }

    return {
      name: defaultBlockchainByCoin.description,
      value: defaultBlockchainByCoin.code,
    };
  };

  return {
    getActiveCoinData,
    getMetaMaskIdChainBySelectedBlockchain,
    getCurrentCoinDefaultBlockchain,
  };
};
