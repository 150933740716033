import axios from 'axios';

const appUrl = window.location.origin;
const urls = import.meta.env.VITE_LOCAL_HTTP_URLS
  ? import.meta.env.VITE_LOCAL_HTTP_URLS.split(',')
  : ['http://localhost:3000', 'http://10.0.2.2:3000'];


const baseUrl = urls.includes(appUrl)
  ? import.meta.env.VITE_APP_API_ENDPOINT
  : `${appUrl}/spa/`;

const apiClient = axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: 'application/json, text/plain, */*',
    'Content-type': 'application/json',
    'Referrer-Policy': 'strict-origin-when-cross-origin',
  },
});

apiClient.interceptors.request.use((config) => {
  const xsrftoken = sessionStorage.getItem('xsrftoken');
  const sessiontoken = sessionStorage.getItem('sessiontoken');
  const headers = {};

  if (xsrftoken) {
    headers['X-XSRF-TOKEN'] = xsrftoken;
  }

  if (sessiontoken) {
    headers['Session-Token'] = sessiontoken;
  }

  if (headers) {
    apiClient.defaults.headers.common = headers;
  }
  return config;
});

apiClient.interceptors.response.use((res) => {
  if (res.headers?.xsrftoken && sessionStorage.getItem('xsrftoken') === null) {
    sessionStorage.setItem('xsrftoken', res.headers.xsrftoken);
  }

  if (res.headers?.sessiontoken && sessionStorage.getItem('sessiontoken') === null) {
    sessionStorage.setItem('sessiontoken', res.headers.sessiontoken);
  }

  return res;
});

export default apiClient;
