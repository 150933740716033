import useProgressBarActions from '@/composables/progress/useProgressBarActions';

const { updateToComplete, resetAllProgress } = useProgressBarActions();

const routes = [
  {
    path: '/nft',
    name: 'nft.order',
    component: () => import('@/views/nft/NFTOrderView.vue'),
    beforeEnter() {
      resetAllProgress();
    },
  },
  {
    path: '/nft/transit',
    name: 'nft.order.transit',
    component: () => import('@/views/nft/NFTOrderView.vue'),
    beforeEnter() {
      resetAllProgress();
    },
  },
  {
    path: '/nft/status/:orderId',
    name: 'nft.summary',
    component: () => import('@/views/nft/NftSummaryView.vue'),
    beforeEnter() {
      updateToComplete(['verification', 'identity', 'payment']);
    },
  },
];

export default routes;
