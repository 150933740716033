/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia';

import { useGlobalStore } from '@/stores/globalStore';

import useFormValidation from '@/composables/validations/useFormValidation';
import useLocalStorage from '@/composables/storage/useLocalStorage';
import useStoreFilters from '@/composables/store/filters';
import usePaymentFilters from '@/composables/filters/usePaymentFilters';
import useOrderEndpoints from '@/composables/api/useOrderEndpoints';
import useBlockchainFilters from '@/composables/filters/useBlockchainFilters';

const { checkLocalStorage, bindLocalStorage, checkPaymentLocalStorage } = useLocalStorage();
const { validateFiatField, validateCoinField } = useFormValidation();
const {
 formHasErrors, getErrorByField, getFormPayload, getErrorInForm, isOrderFromTheApi,
} = useStoreFilters();
const { getFiats, getCoins } = useOrderEndpoints();
const { getActiveCoinData } = useBlockchainFilters();
const formObject = {
  fiatAmount: {
    value: '100',
    error: '',
    required: true,
    validator: validateFiatField,
  },
  fiatType: {
    value: 'USD',
    required: true,
  },
  coinAmount: {
    value: '0.001',
    error: '',
    required: true,
    validator: validateCoinField,
  },
  coinType: {
    value: 'BTC',
    required: true,
  },
  blockchain: {
    value: {
      value: 'BTC',
      name: 'Bitcoin',
    },
    required: true,
  },
  lockFiat: {
    value: true,
    required: true,
  },
  payment: {
    value: null,
    required: true,
  },
};
const { payment, ...rest } = formObject;
const { filterPaymentLimit, filterPaymentProvider } = usePaymentFilters();

export const useSellOrderFormStore = defineStore({
  id: 'sellOrder',
  state: () => ({
    form: {
      ...{
        payment: checkPaymentLocalStorage('sellOrder', formObject),
      },
      ...rest,
    },
    coinData: {
      data: checkLocalStorage('coinData', []),
      coinType: checkLocalStorage('coinType', formObject.coinType.value),
    },
    fiatData: {
      data: checkLocalStorage('fiatData', []),
      fiatType: checkLocalStorage('fiatType', formObject.fiatType.value),
    },
    feeData: {
      networkFee: 0,
      gatewayFee: 0,
      receivedRate: 0,
      quoteValue: 0,
      isLoading: true,
    },
    otpData: {
      email: null,
      mobile: null,
    },
  }),
  getters: {
    formHasErrors: (store) => formHasErrors(store),
    getErrorInForm: (store) => getErrorInForm(store),
    getErrorByField: (store) => (field) => getErrorByField(store)(field),
    getFormPayload: (store) => store.form && getFormPayload(store),
    getFiatType(store) {
      if (!store.form?.fiatType) {
        return null;
      }
      return store.form.fiatType.value;
    },
    getCoinType(store) {
      if (!store.form?.coinType) {
        return null;
      }
      return store.form.coinType.value;
    },
    findAllBlockchainCodesByCoins() {
      // const coinData = store.getActiveCoinData;
      const activeCoin = this.coinData.coinType;
      const coinData = getActiveCoinData(this.coinData.data, activeCoin);

      if (!coinData) return [];
      return coinData.blockchains.map((el) => ({
          value: el.code,
          name: el.description,
        }));
    },
    getCoinData(store) {
      return store.coinData.data;
    },
    getTrackingInformation(store) {
      return {
        fiatAmount: store.form.fiatAmount.value,
        fiatType: store.form.fiatType.value,
        coinAmount: store.form.coinAmount.value,
        coinType: store.form.coinType.value,
        paymentMethod: store.form.payment.value,
      };
    },
    isOrderFromTheApi() {
      return isOrderFromTheApi(this.form);
    },
  },
  actions: {
    setCoinType(coin) {
      this.coinData.coinType = coin;
      bindLocalStorage('coinType', this.coinData.coinType);
    },
    setRequiredFieldByKey(key, isRequired) {
      this.form[key].required = isRequired;
      bindLocalStorage(this.$id, this.form);
    },
    setPaymentServiceProvider(value) {
      this.form.payment.value = value;
      bindLocalStorage(this.$id, this.form);
    },
    setPspTypeCode(value) {
      // Working of this function can be changed to include other parameters of the Payment
      this.form.payment.pspTypeCode = value;
    },
    setPspRequireMobile(value) {
      this.form.payment.require_mobile = value;
    },
    async updateValue(field, val) {
      if (!Object.getOwnPropertyDescriptor(this.form, field)) {
        return;
      }

      if (Object.getOwnPropertyDescriptor(this.form[field], 'validator')) {
        const globalStore = useGlobalStore();
        const { validator } = this.form[field];
        let validatorBinding;

        switch (field) {
          case 'fiatAmount':
            validatorBinding = validator(
              val,
              this.form.fiatType.value,
              filterPaymentLimit(
                this.form.fiatType.value,
                this.form.payment.value,
                globalStore.getPayouts,
              ),
            );

            break;
          case 'coinAmount':
            validatorBinding = validator(
              val,
              this.form.coinType.value,
              this.form.blockchain.value,
              this.coinData.data,
            );
            break;

          default:
            validatorBinding = validator(field, val);
        }

        this.form[field].error = validatorBinding;
      }

      this.form[field].value = val;
      bindLocalStorage(this.$id, this.form);
    },
    async fetchSellFiats() {
      if (this.sellFiats) {
        return this.sellFiats;
      }

      const fiats = await getFiats('sell');
      return this.setFiatData(fiats.data.data.fiats);
    },
    async fetchSellCoins() {
      if (this.sellCoins) {
        return this.sellCoins;
      }

      const coins = await getCoins('sell');
      return this.setCoinData(coins.data.data.coins);
    },
    setCoinData(coinResponse) {
      this.coinData.data = coinResponse;
      bindLocalStorage('coinData', this.coinData.data);
      return this.coinData.data;
    },
    setFiatData(fiatResponse) {
      this.fiatData.data = fiatResponse;
      bindLocalStorage('fiatData', this.fiatData.data);
      return this.fiatData.data;
    },
    updateFeeData(key, value) {
      this.feeData[key] = value;
    },
  },
});
