import { createI18n } from 'vue-i18n';

import numberFormats from './rules/numberFormats';
import datetimeFormats from './rules/datetimeFormats';

import en from './locales/en.json';

const DEFAULT_LOCALE = 'en';

// tto be able to pull into composables as a global var
export const i18n = createI18n({
  locale: DEFAULT_LOCALE,
  fallbackLocale: DEFAULT_LOCALE,
  legacy: false,
  globalInjection: true,
  messages: { en },
  datetimeFormats,
  numberFormats,
  warnHtmlMessage: false, // disable of the Detected HTML in message
});

export default i18n;
