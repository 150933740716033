import { computed, ref } from 'vue';
import { useSnackbar } from '@/packages/vue3-snackbar';

import { useGlobalStore } from '@/stores/globalStore';
import { LOCALES, switchLanguage } from '@/packages/i18n/translation';
import * as CountryCodeByLocales from '@/assets/data/country_codes_by_locales.json';
import LanguageByLocales from '@/assets/data/language_by_locales';

export default () => {
  const globalStore = useGlobalStore();
  const snackbar = useSnackbar();
  const detectedCountryCode = computed(
    () => globalStore.global.detectedGeo?.country_code,
  );
  const isDismissed = ref(false);

  const getCountryByCode = computed(() => CountryCodeByLocales.default.find(
      (country) => country.country === detectedCountryCode.value,
    ));

  const getBrowserLocale = () => {
    const navigatorLocales = navigator.languages
      ? navigator.languages
      : [navigator.language || navigator.userLanguage];
    const locales = [...new Set(navigatorLocales.map((locale) => locale.split('-')[0]))];
    return locales[0];
  };

  // intersection between two array
  // ie. array1: ["en", "pt", "tr"], array2: ["pt"] = true
  const supportedAppLanguagesByUser = (supportedAppLocales, userGeoLocales) => {
    const filteredLocales = userGeoLocales.filter(
      (value) => supportedAppLocales.includes(value.toLowerCase()),
    );

    return filteredLocales;
  };

  const findLaguageName = () => {
    const locale = getBrowserLocale() || getCountryByCode.value?.locales[0];

    if (!locale) {
      return null;
    }

    const supportedLang = LanguageByLocales[locale.toLowerCase()].name;
    return supportedLang;
  };

  const snackbarText = computed(() => {
    const detectedLanguage = findLaguageName();

    return `Would you like to translate to ${detectedLanguage}? <span class="snackbar-button"><button id="clickYes">Yes</button>`;
  });

  const handleGeolocationSnackbar = () => {
    if (!getCountryByCode.value) {
      return;
    }

    const { locales } = getCountryByCode.value;
    const currentLang = globalStore.language.toLowerCase();
    const browserLocale = getBrowserLocale();
    const alreadyTranslated = browserLocale.includes(currentLang) || locales.includes(currentLang);
    const unsupportedGeo = !supportedAppLanguagesByUser(
        Object.values(LOCALES).map(({ value }) => value),
        locales,
      ).length > 0;

    if (
      unsupportedGeo
      || alreadyTranslated
      || isDismissed.value
    ) {
      return;
    }

    snackbar.add({
      type: 'info',
      text: snackbarText.value,
      dismissible: true,
      duration: 15000,
    });

    // let it appear to dom
    setTimeout(() => {
      document.getElementById('clickYes').addEventListener('click', () => {
        switchLanguage(getCountryByCode.value.locales[0]);
        snackbar.clear();
        isDismissed.value = true;
      });
      document
        .querySelector('.vue3-snackbar-message-close')
        .addEventListener('click', () => {
          isDismissed.value = true;
        });
    }, 1000);
  };

  return {
    detectedCountryCode,
    handleGeolocationSnackbar,
  };
};
