import useApiHelpers from '@/composables/api/helpers';

const { getRequest, postRequest } = useApiHelpers();

export default () => {
  const getSiftData = async () => {
    const response = await getRequest('/sift');
    return response.data;
  };

  const sendNsureDeviceId = (payload) => postRequest('/nsure/deviceId', payload, false, false, {}, false);

  return {
    getSiftData,
    sendNsureDeviceId,
  };
};
