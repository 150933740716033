const lowerCaseItem = (string) => string.toLowerCase();
const capitaliseFirstChar = (string) => string.charAt(0).toUpperCase() + string.slice(1);

// https://stackoverflow.com/questions/2970525/converting-any-string-into-camel-case
const camelize = (str) => str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase());

// https://stackoverflow.com/a/52584017
const unCamelizeIntoNormalStr = (str) => (typeof str === 'string'
  ? str
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim()
  : str);

/**
 *
 *
 * * Formats a string to add ellipsis in middle if char count is > 25
 * * ie. ILoveMemesAndCryptocurrency -> ILoveMeme...ocurrency
 * * @param1 {Str} word
 * * @param2 {Int} Number of chars it will allow on either side / 2
 * * @returns {*}  str
 */
const obfuscateWithMiddleEllipsis = (word, charLimit = 15) => {
  if (typeof word === 'undefined' || word.length < charLimit) {
    return word;
  }

  const ellipsis = '...';
  const charsOnEitherSide = Math.floor(charLimit / 2) - ellipsis.length;

  return (
    word.slice(0, charsOnEitherSide) + ellipsis + word.slice(-charsOnEitherSide)
  );
};

const toKebabCase = (str) => str.toLowerCase().replace(' ', '-');

const isValueString = (value) => Object.prototype.toString.call(value) === '[object String]';

/**
 * * Formats a currency string to a correct currency string.
 * * Eg. 2000 -> $2,000
 * * @param {value} str
 * * @param {currency} str
 * * @returns {*} str
 */
const toCurrencyFormat = (value, currency) => {
  if (!currency || currency === '') {
    return '';
  }

  let formatter;

  try {
    formatter = new Intl.NumberFormat(navigator.language, {
      style: 'currency',
      currency,
      currencyDisplay: 'symbol',
    });
  } catch (err) {
    formatter = new Intl.NumberFormat(navigator.language, {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'symbol',
    });
  }
  return formatter.format(value);
};

const addThousandSeperator = (value) => Number(value).toLocaleString(undefined);

/**
 * * Remove spaces on a string.
 * * @param {str} str
 * * @returns str
 */
const removeWhiteSpace = (str) => (str ? str.replace(/ /g, '') : '');

/**
 * * Accepts a string which will include [[url | word]]  and transforms it into a tag links.
 * * @param {str} str
 * * @returns str
 * * ie.  'checkout our ((https://memedreams.crypto , cool website)).'
 */
// https://stackoverflow.com/questions/42649866/best-practice-for-html-tags-in-vue-i18n-translations
const transformLinks = (text) => {
  const linkExpr = /\(\((.*?)\)\)/gi;

  return text.replace(linkExpr, (expr, value) => {
    const parts = value.split(',');

    const link = `<a target='_blank' rel='nofollow' href="${parts[0]}">${parts[1]}</a>`;
    return link;
  });
};

export {
  lowerCaseItem,
  capitaliseFirstChar,
  camelize,
  unCamelizeIntoNormalStr,
  obfuscateWithMiddleEllipsis,
  toCurrencyFormat,
  toKebabCase,
  isValueString,
  addThousandSeperator,
  removeWhiteSpace,
  transformLinks,
};
