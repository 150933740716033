/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia';
import useApiHelpers from '@/composables/api/helpers';

const { postRequest } = useApiHelpers();

export const useRouteStore = defineStore({
  id: 'route',
  state: () => ({
    history: JSON.parse(sessionStorage.getItem('routeHistory')) || [],
  }),
  actions: {
    push(to) {
      this.history.push(to.fullPath);
      sessionStorage.setItem('routeHistory', JSON.stringify(this.history));
    },
    submit() {
      postRequest('/logging/log', {
        data: {
          event_name: 'ORDER_JOURNEY',
          route: this.history,
        },
      });
    },
  },
  getters: {
    getHistory: (state) => state.history,
  },
});
