import { nextTick } from 'vue';
import { i18n } from '@/packages/i18n';
import { useGlobalStore } from '@/stores/globalStore';

export const LOCALES = [
  {
    name: 'Czech',
    value: 'cs',
  },
  {
    name: 'Danish',
    value: 'da',
  },
  {
    name: 'German',
    value: 'de',
  },
  {
    name: 'Greek',
    value: 'el',
  },
  {
    name: 'English',
    value: 'en',
  },
  {
    name: 'Spanish',
    value: 'es',
  },
  {
    name: 'French',
    value: 'fr',
  },
  {
    name: 'Italian',
    value: 'it',
  },
  // {
  //   name: 'Japanese',
  //   value: 'ja',
  // },
  // {
  //   name: 'Korean',
  //   value: 'ko',
  // },
  {
    name: 'Lithuanian',
    value: 'lt',
  },
  {
    name: 'Dutch',
    value: 'nl',
  },
  {
    name: 'Polish',
    value: 'pl',
  },
  {
    name: 'Portuguese',
    value: 'pt',
  },
  {
    name: 'Turkish',
    value: 'tr',
  },
  // {
  //   name: 'Chinese (simplified)',
  //   value: 'zh_cn',
  // },
];

/**
 * Get Supported Locales
 *
 * @returns {array} supported locales
 */
const supportedLocales = () => LOCALES.map((locale) => locale.value);

/**
 * Set Current Locale
 */
const setCurrentLocale = (newLocale) => {
  i18n.global.locale.value = newLocale;
};

export const getPersistedLocale = () => {
  const persistedLocale = localStorage.getItem('locale');

  if (supportedLocales().some((locale) => locale === persistedLocale)) {
    return persistedLocale;
  }

  return 'en';
};

export const selectedLocale = () => LOCALES.find((locale) => locale.value === getPersistedLocale());

/**
 * Load Locale Messages
 */
export const loadLocaleMessages = async (locale = 'en') => {
  try {
    const messages = await import(`./locales/${locale.toLowerCase()}.json`);
    i18n.global.setLocaleMessage(locale, messages.default);
    return nextTick();
  } catch (error) {
    console.error(error);
  }
};

/**
 * Switch Locale
 */
export const switchLanguage = async (newLocale) => {
  const globalStore = useGlobalStore();
  await loadLocaleMessages(newLocale);
  setCurrentLocale(newLocale);
  globalStore.setLanguage(newLocale);
};
