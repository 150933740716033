/* eslint-disable no-console */
import useThirdPartyScriptEndpoints from '@/composables/api/useThirdPartyScriptEndpoints';

const { sendNsureDeviceId } = useThirdPartyScriptEndpoints();

const appId = import.meta.env.VITE_NSURE_APP_ID;

// Below function will be called only if nSure script is loaded
window.nSureAsyncInit = (deviceId) => {
  try {
    window.nSureSDK.init(appId);
  } catch (error) {
    console.error('Error initializing nSure SDK', error);
  }
  if (deviceId) {
    // Send it to the SPA-BE
    sendNsureDeviceId({ deviceId });
  }
};

const addNsureScript = () => {
  const sandboxNsureSdk = 'https://sandbox-sdk.nsureapi.com/sdk.js';
  const prodNsureSdk = 'https://sdk.nsureapi.com/sdk.js';

  const nSureSdkUrl = import.meta.env.VITE_ENV === 'production' ? prodNsureSdk : sandboxNsureSdk;

  const nSureScript = document.createElement('script');
  nSureScript.setAttribute('src', nSureSdkUrl);
  document.body.appendChild(nSureScript);
  return nSureScript;
};

if (appId) {
  try {
    addNsureScript().onload = () => {
      console.log('nSure script loaded');
    };
  } catch (error) {
    console.error('Error loading nSure script', error);
  }
}
