/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import useScriptTagSetup from '@/composables/helpers/sciptTagSetup';
import { useScriptTag } from '@vueuse/core';
import useThirdPartyScriptEndpoints from '@/composables/api/useThirdPartyScriptEndpoints';

const { getSiftData } = useThirdPartyScriptEndpoints();
const { setupScriptTag } = useScriptTagSetup();
export default () => {
  const useSiftScript = async () => {
    const { session_id, user_id, sift_beacon_key } = await getSiftData();

    window._sift = window._sift || [];
    window._sift.push(['_setAccount', sift_beacon_key]);
    window._sift.push(['_setUserId', user_id]);
    window._sift.push(['_setSessionId', session_id]);
    window._sift.push(['_trackPageview']);

    const loadSift = () => {
      useScriptTag('https://cdn.sift.com/s.js');
    };

    if (window.attachEvent) {
      window.attachEvent('onload', loadSift);
    } else {
      window.addEventListener('load', loadSift, false);
    }
  };

  const useGoogleMapsScript = (mountedCallback) => {
    if (!window.google) {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDDvzXKisgpiVIVABXgVMdjVHgLoZwNZ38&libraries=places';
      const x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
      // Below is important.
      // We cannot access google.maps until it's finished loading
      s.addEventListener('load', (e) => {
        if (mountedCallback) mountedCallback();
      });
    } else if (mountedCallback) mountedCallback();
  };

  return {
    useSiftScript,
    useGoogleMapsScript,
  };
};
