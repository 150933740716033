export default function useLocalStorage() {
  //  localStorage doesn't set validator fns
  // this fn rebinds the validator() set within form stores
  const checkFormValidatorBinding = (data, checkedData) => {
    if (!data) {
      return checkedData;
    }

    Object.keys(data).forEach((field) => {
      if (
        !data[field]
        || typeof data[field] !== 'object'
        || !Object.getOwnPropertyDescriptor(data[field], 'validator')
      ) {
        return;
      }

      const { validator } = data[field];

      if (!validator) {
        return;
      }
      if (checkedData[field]) {
        checkedData[field].validator = validator;
      }
    });

    return checkedData;
  };
  const checkLocalStorage = (key, data) => {
    const storageRef = localStorage.getItem(key);

    const checkedData = storageRef === null ? data : JSON.parse(storageRef);
    // return checkedData;

    return checkFormValidatorBinding(data, checkedData);
  };

  const checkLocalStorageByKeys = (key1, key2, defaultData) => {
    const storageRef = JSON.parse(localStorage.getItem(key1));
    const storageRefDepthOne = storageRef && storageRef[key2];
    const checkedData = storageRefDepthOne || defaultData[key2];

    return checkedData;
  };

  const bindLocalStorage = (key, data) => {
    if (data !== undefined) {
      localStorage.setItem(key, JSON.stringify(data));
    }
  };

  const clearAllStorage = () => {
    localStorage.clear();
  };

  // HE-971 Fix such that payment field are always set to required on load from local storage
  const checkPaymentLocalStorage = (store, defaultData) => {
    const paymentData = checkLocalStorageByKeys(store, 'payment', defaultData);

    const { required, ...rest } = paymentData;

    return {
      ...{
        required: true,
      },
      ...rest,
    };
  };

  const removeLocalStorageByKey = (key) => {
    if (!localStorage.getItem(key)) {
      return;
    }

    localStorage.removeItem(key);
  };

  return {
    checkLocalStorage,
    bindLocalStorage,
    clearAllStorage,
    checkLocalStorageByKeys,
    checkPaymentLocalStorage,
    removeLocalStorageByKey,
  };
}
