import { useGlobalStore } from '@/stores/globalStore';
import { useOrderFormStore } from '@/stores/forms/orderFormStore';

function expressCheckoutEnabled() {
  const globalStore = useGlobalStore();
  return globalStore?.expressCheckoutEnabled ?? false;
}

function isExpressCheckoutEnabledForMerchAndOrder() {
  const orderStore = useOrderFormStore();

  return (expressCheckoutEnabled() && orderStore.isAnExpressCheckoutOrder);
}

export { expressCheckoutEnabled, isExpressCheckoutEnabledForMerchAndOrder };
