import { useGlobalStore } from '@/stores/globalStore';

const useTheme = () => {
  // example query {url}/?backgroundColor=696969&primaryColor=c8b410&secondaryColor=16c810&textColor=c8b410
  const themeIsFromUrl = async (query) => {
    const globalStore = useGlobalStore();
    const merchantColorsFromStorage = JSON.parse(
      localStorage.getItem('merchantColors'),
    );

    const isThemeQuery = Promise.allSettled([query]).then((res) => {
      const [themeQuery] = res;
      const searchValues = [
        'primaryColor',
        'secondaryColor',
        'backgroundColor',
        'textColor',
      ];

      if (!themeQuery) {
        return false;
      }

      const { searchParams } = new URL(document.location);
      const searchParamsHasThemeFromUrl = themeQuery.value
        ? Object.keys(themeQuery.value).some((key) => searchValues.includes(key))
        : searchValues.some((value) => searchParams.has(value));

      return (
        (searchParamsHasThemeFromUrl && searchParams.size > 0)
        || merchantColorsFromStorage
      );
    });

    return isThemeQuery;
  };

  const updateThemeFromUrl = () => {
    const store = useGlobalStore();
    const { searchParams } = new URL(window.location.href);
    const merchantColorsFromStorage = JSON.parse(
      localStorage.getItem('merchantColors'),
    );

    const primary = searchParams.get('primaryColor')
      || merchantColorsFromStorage?.primary
      || '4b9cdd';

    const secondary = searchParams.get('secondaryColor')
      || merchantColorsFromStorage?.secondary
      || '00d6bf';

    // if a background has not been set default to store theme
    const background = searchParams.get('backgroundColor')
      || merchantColorsFromStorage?.background
      || '111630';
    const updateBackgroundColor = !!background;

    // defaults to white text
    const textColor = searchParams.get('textColor')
      || merchantColorsFromStorage?.textColor
      || 'ffffff';

    // if no background has been set then default to banxa theme
    if (!updateBackgroundColor) {
      const useLightTheme = store.global.theme === 'LIGHT';
      updateTheme(useLightTheme);
    }

    updateColor(
      {
        primary: `#${primary}`,
        secondary: `#${secondary}`,
        background: `#${background}`,
        textColor: `#${textColor}`,
      },
      updateBackgroundColor,
    );

    localStorage.setItem(
      'merchantColors',
      JSON.stringify({
        primary,
        secondary,
        background,
        textColor,
      }),
    );
  };

  const updateAppTheme = (query) => {
    if (!query) {
      return updateThemeFromStore();
    }

    themeIsFromUrl(query).then((isThemeQuery) => {
      if (!isThemeQuery) {
        return updateThemeFromStore();
      }

      return updateThemeFromUrl();
    });
  };

  /**
   * Adds/removes 'lightmode' class to the root html element
   * depending on boolean value light theme in pinia store.
   */
  const updateThemeFromStore = () => {
    const store = useGlobalStore();
    const useLightTheme = store.global.theme === 'LIGHT';
    updateTheme(useLightTheme);
    if (store.global?.merchant?.theme) {
      updateColor(store.global.merchant?.theme, store.getIsGreyLabel);
    }
  };

  const updateTheme = (useLightTheme) => {
    const rootEl = document.documentElement;
    const rootUsingLightTheme = rootEl.classList.contains('lightmode');

    if (useLightTheme) {
      if (!rootUsingLightTheme) {
        rootEl.classList.add('lightmode');
      }
    } else if (rootUsingLightTheme) {
      rootEl.classList.remove('lightmode');
    }
  };

  const updateColor = (color, isSetBackgound = false) => {
    const root = document.documentElement;
    const {
 primary, secondary, background, textColor,
} = color;

    if (primary) {
      root.style.setProperty('--primary', `${primary}`);
    }
    if (secondary) {
      root.style.setProperty('--secondary', `${secondary}`);
    }
    if (background && isSetBackgound) {
      root.style.setProperty('--background', `${background}`);
      root.style.setProperty('--settings-overlay-background', `${background}`);
      root.style.setProperty('--dropdown-background', `${background}`);
      root.style.setProperty('--modal-background', `${background}`);
      // if background is set, disable floating background gradients
      root.style.setProperty('--backgroundGradientOpacity', '0.0');
    }
    if (textColor) {
      root.style.setProperty('--text-color', textColor);
      root.style.setProperty('--footer-text-color', textColor);
      root.style.setProperty('--input-place-holder-text-color', textColor);
    }
  };

  /**
   * Validates theme query, updates the pinia store with the value used in query, updates the theme.
   * @param {string} queryVal value passed in for 'theme' in the route
   */
  const updateThemeFromQuery = (queryVal) => {
    const globalStore = useGlobalStore();

    const isLightmodeRequest = ['lightmode', 'light'].includes(
      queryVal.toLowerCase(),
    );
    const theme = isLightmodeRequest ? 'LIGHT' : 'DARK';
    globalStore.setTheme(theme);

    updateThemeFromStore();
  };

  const updateThemeFromMerchant = (theme) => {
    const globalStore = useGlobalStore();
    // color should always be updated
    updateColor(theme, globalStore.getIsGreyLabel);

    if (globalStore.getIsThemeSetted) {
      // don't override the setting when the theme is set
      return;
    }

    const themeMode = theme?.mode?.toUpperCase() || 'DARK';
    globalStore.setTheme(themeMode);
    // Prioritise the local storage before quering the API
    updateTheme(themeMode === 'LIGHT');
  };

  return {
    updateThemeFromStore,
    updateThemeFromQuery,
    updateThemeFromMerchant,
    updateAppTheme,
    themeIsFromUrl,
  };
};

export default useTheme;
