<template>
  <article
    class="vue3-snackbar-message"
    :class="[
      props.message.type || 'custom',
      props.messageClass,
      props.borderClass,
      {
        'has-background': props.message.background,
        'has-border': props.borderClass,
        'is-dense': props.dense,
        'shake-baby-shake': hasShake,
      },
    ]"
    :style="{
      '--message-background': props.message.background,
    }"
  >
    <slot name="message-inner" :message="props.message">
      <div class="vue3-snackbar-message-wrapper">
        <div v-if="icon" class="vue3-snackbar-message-icon">
          <slot name="message-icon" :message="props.message" :icon="icon">
            <vue3-icon v-bind="icon" role="img" />
          </slot>
        </div>
        <div class="vue3-snackbar-message-content">
          <slot
            name="message-badge"
            :message="props.message"
            :count="props.message.count"
          >
            <div
              v-if="props.message.count > 1"
              class="vue3-snackbar-message-badge"
            >
              {{ props.message.count }}
            </div>
          </slot>
          <slot
            name="message-content"
            :message="props.message"
            :title="props.message.title"
            :text="props.message.text"
          >
            <div
              class="vue3-snackbar-message-title"
              v-html="props.message.title || props.message.text"
            />
            <div
              v-if="props.message.title && props.message.text"
              class="vue3-snackbar-message-additional"
              v-html="props.message.text"
            />
          </slot>
        </div>
        <div class="spacer" />
        <div class="vue3-snackbar-message-close">
          <button
            v-if="props.message.dismissible !== false"
            @click="dismissClick"
          >
            <slot name="message-close-icon" :message="props.message">
              <vue3-icon type="mdi" :path="mdiClose" />
            </slot>
          </button>
        </div>
      </div>
    </slot>
  </article>
</template>

<script setup>
import Vue3Icon from 'vue3-icon';
import {
 onMounted, watch, ref, computed,
} from 'vue';
import {
  mdiCheckCircle,
  mdiClose,
  mdiInformationOutline,
  mdiAlertOctagonOutline,
  mdiAlertOutline,
} from '@mdi/js';
import '@/packages/vue3-snackbar/style.scss';

const emit = defineEmits(['dismiss']);
const props = defineProps({
  borderClass: {
    type: String,
    default: '',
  },
  message: {
    type: Object,
    default: () => ({}),
  },
  messageClass: {
    type: String,
    default: '',
  },
  dense: {
    type: Boolean,
    default: false,
  },
});

let timeout = null;
let shakeTimeout = null;

const hasShake = ref(false);

const setMessageTimeout = () => {
  const messageDuration = !props.message.duration && !props.message.dismissible
      ? 4000
      : props.message.duration;
  timeout = setTimeout(dismiss, messageDuration);
};

onMounted(() => {
  setMessageTimeout();
});
watch(
  () => props.message.count,
  (v) => {
    if (v === 1) return false;
    clearTimeout(timeout);
    clearTimeout(shakeTimeout);
    shakeTimeout = setTimeout(() => {
      hasShake.value = false;
    }, 1000);
    hasShake.value = true;
    setMessageTimeout();
  },
);
const dismissClick = () => {
  if (timeout) clearTimeout(timeout);
  dismiss();
};
const dismiss = () => {
  emit('dismiss', props.message);
};
const types = {
  success: {
    path: mdiCheckCircle,
  },
  info: {
    path: mdiInformationOutline,
  },
  warning: {
    path: mdiAlertOutline,
  },
  error: {
    path: mdiAlertOctagonOutline,
  },
};
/**
 * Return the options passed to the vue3-icon component
 */
const icon = computed(() => {
  const preset = types[props.message.type];
  // If a preset is defined
  if (preset) {
    preset.type = 'mdi';
    return preset;
  }
  // If the user passes their own icon object
  if (props.message.icon && typeof props.message.icon === 'object') {
    return props.message.icon;
  }
  // Otherwise return an empty icon
  return {
    path: '',
    type: 'default',
  };
});
</script>
