/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia';
import { ref } from 'vue';

import filters from '@/composables/store/filters';
import useGlobalActions from '@/composables/store/globalActions';

const { formHasErrors, getErrorByField } = filters();
const { updateErrorFieldByKey } = useGlobalActions();

const formObject = ref({
  /**
   * NOTE: Occupation is not mentioned as required because occupation is being composed
   * based on the values of occupationOther and occupationDropdown
   */
  occupation: {
    value: null,
  },
  occupationIndustry: {
    value: null,
  },
  occupationOther: {
    value: null,
    required: false,
    error: '',
  },
  occupationDropdown: {
    value: null,
    required: true,
  },
});

export const useOccupationStore = defineStore({
  id: 'occupation',
  state: () => ({
    form: formObject,
  }),
  getters: {
    formHasErrors: (store) => formHasErrors(store),
    getErrorByField: (store) => (field) => getErrorByField(store)(field),
  },
  actions: {
    updateValue(field, val) {
      if (!Object.getOwnPropertyDescriptor(this.form, field)) {
        return;
      }

      if (Object.getOwnPropertyDescriptor(this.form[field], 'validator')) {
        const { validator } = this.form[field];
        this.form[field].error = validator(field, val);
      }
      this.form[field].value = val;
    },

    updateErrorFieldByKey(field, error) {
      updateErrorFieldByKey(this.form, field, error);
    },

    updateRequiredFieldForKey(field, isRequired) {
      if (
        !field
        || !Object.getOwnPropertyDescriptor(this.form, field)
        || !Object.getOwnPropertyDescriptor(this.form[field], 'required')
      ) {
        return;
      }

      this.form[field].required = isRequired;
    },
  },
});
