/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia';

export const useNotificationStore = defineStore({
  id: 'notification',
  state: () => ({
    banner: {
      order: {},
      orderStatus: {},
    },
  }),
  actions: {
    setBanner(orderBanner, orderStatusBanner) {
      if (orderBanner) {
        this.banner.order = { ...orderBanner, toShow: true };
      }

      if (orderStatusBanner) {
        this.banner.orderStatus = { ...orderStatusBanner, toShow: true };
      }
    },
    dismissBanner(routeName) {
      if (!this.banner.order || !this.banner.orderStatus) {
        return;
      }

      if (routeName && routeName.includes('order')) {
        this.banner.order.toShow = false;
      }
      if (routeName && routeName.includes('summary')) {
        this.banner.orderStatus.toShow = false;
      }
    },
  },
  getters: {
    getBannerData: (store) => store.banner,
  },
});
