import useApiHelpers from '@/composables/api/helpers';

export default () => {
  const { getRequest } = useApiHelpers();

  /**
   * Checks the url for id param to then call the getOrderInfo api
   * @param {any} callback
   * @returns {Boolean callback| Promise callback}
   * */

  const getApiOrder = () => {
    const params = new URLSearchParams(window.location.search);

    if (!params.has('id')) {
      return false;
    }
    return getRequest(`/getOrderInfo/${params.get('id')}`);
      // .then((res) => {
      //   // TODO :: push order id to clarity
      //   return res;
      // })
  };

  const getPAPIOrder = () => {
    const params = new URLSearchParams(window.location.search);

    if (!params.has('initId')) {
      return false;
    }
    return getRequest(`/getPAPIOrderInfo/${params.get('initId')}`);
      // .then((res) => {
      //   // TODO :: push order id to clarity
      //   return res;
      // })
  };

  const getMerchSettings = () => getRequest('/get-base-merchant-settings');

  const getNotificationSettings = () => getRequest('/notifications');

  return {
    getApiOrder,
    getMerchSettings,
    getNotificationSettings,
    getPAPIOrder,
  };
};
