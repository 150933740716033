import * as CountryCodeByLocales from '@/assets/data/country_codes_by_locales.json';

import useLocalStorage from '@/composables/storage/useLocalStorage';

const { checkLocalStorage } = useLocalStorage();

const currencyFromCountry = (countryCode) => {
  const locale = CountryCodeByLocales.default.find((loc) => loc.country === countryCode);

  if (!locale) {
    throw new Error(`Country code ${countryCode} not found in country_codes_by_locales`);
  }

  return locale.mappedCurrency;
};

const countryFromCurrency = (currencyCode) => Object.keys(CountryCodeByLocales.default).find(
      (countryCode) => CountryCodeByLocales.default[countryCode].mappedCurrency === currencyCode,
    );

const fetchCurrencyFromStorage = () => {
  const currency = checkLocalStorage('global');
  const defaultFiat = currency?.defaultFiat;
  return defaultFiat || 'USD';
};

export { currencyFromCountry, countryFromCurrency, fetchCurrencyFromStorage };
