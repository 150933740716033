import useApiHelpers from './helpers';

const { getRequest } = useApiHelpers();

export default function useIdentityQueueEndpoints() {
  const getPendingIdentityDocuments = async () => {
    const maxRepollAttempts = 3;
    let attempts = 0;
    let response;
    let checkInterval;

    response = await getRequest('/kyc/queue');

    if (response?.data?.rel?.repoll && attempts < maxRepollAttempts) {
      attempts += 1;
      checkInterval = setTimeout(async () => {
        response = await getRequest('/kyc/queue');
      }, 250);
    } else {
      clearTimeout(checkInterval);
      return response;
    }
  };

  return {
    getPendingIdentityDocuments,
  };
}
