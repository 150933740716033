import { WalletAddressValidator } from 'multi-blockchain-wallet-validator/src/WalletAddressValidator';

export default function walletAddressValidator() {
  /*
   * Pass an array of blockchains to validate against.
   * @param {String} walletAddress
   * @param {Array} blockchains
   * @returns {Object} ie. { coinName : validated Boolean, coinName : validated Boolean }
   */
  const validateWalletByBlockchains = async (walletAddress, blockchains) => {
    const self = this;
    const listOfValidatedWalletAddressByBlockchains = await WalletAddressValidator(walletAddress, blockchains, self);

    return listOfValidatedWalletAddressByBlockchains;
  };

  /*
   * Pass an array of a single blockchain to validate against.
   * @param {String} walletAddress
   * @param {Array} blockchain
   * @returns {Object} {coinName : validated Boolean }
   */
  const validateWalletBySelectedBlockchain = async (
    walletAddress,
    blockchain,
  ) => {
    const selectedBlockChain = blockchain[0]?.value ? [blockchain[0].value] : blockchain;

    const self = this;
    const validatedBlockchain = await WalletAddressValidator(
      walletAddress,
      selectedBlockChain,
      self,
    );

    return validatedBlockchain;
  };

  return {
    validateWalletByBlockchains,
    validateWalletBySelectedBlockchain,
  };
}
