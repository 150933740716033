/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia';

export const useArchaxStore = defineStore({
  id: 'archax',
  state: () => ({
    bannerHeight: '0px',
  }),
  actions: {
    setBannerHeight(height) {
      this.bannerHeight = `${height}px`;
    },
    getters: {
      getBannerHeight: () => this.bannerHeight,
    },
  },
});
