export default () => {
  const filterPaymentDataByPaymentName = (payments, searchPaymentName) => {
    if (payments.length <= 0 || !searchPaymentName) {
      return [];
    }

    return payments.filter(
      (payment) => payment.code.toLowerCase() === searchPaymentName.toLowerCase(),
    );
  };

  const filterPaymentsByFiat = (activeFiat, payments) => payments.filter(
    (payment) => payment.supported_fiat.includes(activeFiat.toUpperCase()),
  );

  const filterPaymentProvider = (payments, paymentName) => {
    const findMatchingPaymentProviderByName = filterPaymentDataByPaymentName(
      payments,
      paymentName,
    );

    return findMatchingPaymentProviderByName[0];
  };

  const sortApplePayAsFirstPayment = (payments) => {
    if (!window.ApplePaySession) {
      return payments;
    }

    const applePayPaymentProviders = ['WORLDPAYAPPLE', 'PRIMERAP'];

    // sort ApplePay always as first payment
    const applePayIndex = payments.findIndex(
      (payment) => applePayPaymentProviders.some(
        (appleProvider) => payment.paymentType === appleProvider,
      ),
    );

    const applePayExists = applePayIndex !== -1;
    const applePayIsFirst = applePayIndex === 0;
    // if it doesn't exist or is the first payment already
    if (!applePayExists || applePayIsFirst) {
      return payments;
    }

    payments.splice(0, 0, payments[applePayIndex]);
    payments.splice(applePayIndex + 1, 1);

    return payments;
  };

  const filterPaymentsByCoins = (activeCoin, payments) => {
    const filteredPayments = payments.filter(
      (payment) => payment.supported_coin.includes(activeCoin.toUpperCase()),
    );

    return sortApplePayAsFirstPayment(filteredPayments);
  };

  const filterPaymentsByFiatAndCoin = (activeFiat, activeCoin, payments) => filterPaymentsByCoins(
      activeCoin,
      filterPaymentsByFiat(activeFiat, payments),
    );

  /**
   * Pulls all current selected payment's and
   * Active Fiat's transactional limits.
   *
   * @param activeFiat {Object}
   * @param activePayment {Object}
   * @param payments {Array of Objects}
   * @returns {Object} {min, max}
   * ie. applepaygiv : {min: 69, max: 6969}
   */

  const filterPaymentLimit = (activeFiat, activePayment, payments) => {
    if (!activeFiat || !activePayment || !payments) {
      return;
    }
    const activePaymentData = filterPaymentsByFiat(activeFiat, payments).find(
      (payment) => payment.code.toLowerCase() === activePayment?.toLowerCase(),
    );

    if (!activePaymentData || activePaymentData.length <= 0) {
      return;
    }

    const limitsByFiat = activePaymentData.transaction_limits.find(
      (fiat) => fiat.fiat_code.toLowerCase() === activeFiat?.toLowerCase(),
    );

    if (!limitsByFiat || limitsByFiat.length <= 0) {
      return;
    }

    return limitsByFiat;
  };

  const getDefaultPayment = (currentPayment, validPSPList) => {
    if (validPSPList.length === 0) {
      return null;
    }
    if (!currentPayment) {
      return validPSPList[0];
    }
    const filteredValidPSPList = validPSPList.filter(
      (payment) => payment.code?.toUpperCase() === currentPayment.toUpperCase(),
    );
    return filteredValidPSPList.length > 0
      ? filteredValidPSPList[0]
      : validPSPList[0];
  };

  /**
   * checks user's local browser + if it has applepaySession
   * @param {Array} payments
   * @returns {Array}
   */
  const removeApplepayByUserSession = (payments) => {
    const applePayPaymentProviders = ['WORLDPAYAPPLE', 'PRIMERAP'];
    if (!window.ApplePaySession) {
      return payments.filter(
        (payment) => !applePayPaymentProviders.includes(payment.paymentType),
      );
    }

    return payments;
  };

  return {
    filterPaymentsByFiatAndCoin,
    filterPaymentDataByPaymentName,
    filterPaymentLimit,
    filterPaymentProvider,
    getDefaultPayment,
    filterPaymentsByFiat,
    filterPaymentsByCoins,
    removeApplepayByUserSession,
    sortApplePayAsFirstPayment,
  };
};
