export const checkClarity = () => (!!window.clarity);

export const trackClarityTag = (key, value) => {
  if (checkClarity()) {
    window.clarity('set', key.toString(), value.toString());
  }
};

export const trackClarityEvent = (value) => {
  if (checkClarity()) {
    window.clarity('event', value.toString());
  }
};

export const trackTTS = (key, value) => {};
