/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import { isObject } from '@vueuse/core';
import { isValueString } from '@/composables/helpers/useStringHelpers';
import { IDENTITY_MAPPING_ROUTES } from './constants';

function createMapping(key, value, index) {
  const banxaIdentityPages = Object.keys(IDENTITY_MAPPING_ROUTES.BANXA);
  const sumsubIdentityPages = Object.keys(IDENTITY_MAPPING_ROUTES.SUMSUB);
  const plaidIdentityPages = Object.keys(IDENTITY_MAPPING_ROUTES.PLAID);

	const provider = isObject(value)
    ? value?.provider.toUpperCase()
    : value.toUpperCase();
  const weight = isObject(value) ? value?.weight : index;

	if (!isValueString(provider)) {
    console.error('KYC Provider value is not returned');
    return;
  }

  if (!Object.getOwnPropertyDescriptor(IDENTITY_MAPPING_ROUTES, provider)) {
    console.error(`Wrong provider: ${provider} for identity`);
    return;
  }

  if (
    (provider === 'BANXA' && !banxaIdentityPages.includes(key))
    || (provider === 'SUMSUB' && !sumsubIdentityPages.includes(key))
    || (provider === 'PLAID' && !plaidIdentityPages.includes(key))
  ) {
    console.error(`Identity: ${key} has no mapped route for send to`);
    return;
  }

	const {
    route: url, name: title, description, icon,
  } = IDENTITY_MAPPING_ROUTES[provider][key];

  return {
    url,
    provider,
    title,
    weight,
    description,
    icon,
    centryKey: key,
  };
}

const handleIdentityProgressDoc = (self, key, value, index) => {
  const mappedDoc = createMapping(
    key,
    value,
    index,
  );

  if (!mappedDoc) {
    return;
  }

  const {
    url,
    provider,
    title,
    weight,
    description,
    icon,
    centryKey,
  } = mappedDoc;

	const noNewKeys = !self.identityProgress.find(
    (idDoc) => idDoc?.title.toLowerCase() === title.toLowerCase()
      && idDoc?.provider.toUpperCase() === provider.toUpperCase()
      && idDoc?.status !== 'completed',
  );

	if (!noNewKeys) {
    return;
  }

  if (self.requiresDocRecollection) {
    const foundDoc = self.identityProgress.find(
      (idDoc) => idDoc?.title.toLowerCase() === title.toLowerCase()
      && idDoc?.provider.toUpperCase() === provider.toUpperCase(),
    );

    if (!foundDoc) {
      self.identityProgress.push({
        url,
        provider,
        title,
        weight,
        description,
        icon,
        centryKey,
        status: 'action-required',
      });
      return;
    }

    foundDoc.status = 'action-required';
    return;
  }

  self.identityProgress.push({
    url,
    provider,
    title,
    weight,
    description,
    icon,
    centryKey: key,
    status: 'pending',
  });
};

const completePreviousIdentityDocs = (identityProgress, newQueueDocs) => {
  if (newQueueDocs.length === 0) {
    return identityProgress.forEach(
      (idDoc) => {
        if (idDoc) idDoc.status = 'completed';
      },
    );
  }

  const newDocsMapped = newQueueDocs.reduce((acc, key) => {
    const index = newQueueDocs.indexOf(key);
    acc[index] = createMapping(
      key[0],
      key[1],
      index,
    );
    return acc;
  }, []);

  // finds the common document between current identity docs and new docs,
  // and update the difference to be completed
  return identityProgress.filter(
      (idDoc) => !newDocsMapped.some(
        (newDoc) => (idDoc.centryKey === newDoc.centryKey) && (idDoc.provider === newDoc.provider),
      ),
    ).forEach(
      (idDoc) => {
      if (idDoc) idDoc.status = 'completed';
      },
    );
};

export default {
	handleIdentityProgressDoc,
  completePreviousIdentityDocs,
  createMapping,
};
